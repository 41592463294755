<div class="top-bar">
  <div class="chevron-block top-chevron" *ngIf="isMenuOk" (click)="toggleTopMenu()">
    <ion-icon name="chevron-up-outline" class="chevron-icon"></ion-icon>
  </div>
  <div class="sidebar-container " #menuContainer>
    <ion-row id="target1" class="row-container" *ngFor="let menu of menuList let i = index">
      <ion-col class="borderhei" col-12 routerLink="{{menu.routerLink}}" 
      routerLinkActive="selected-list" (click)="clearUp()">
        <div class="icon">
          <img src="{{menu.menuItemUrl}}" class="image-icon" alt="">
          <ion-label class="sub-title">
            {{menu.menuItemDescription | translate}}
          </ion-label>
        </div>
      </ion-col>
    </ion-row>
  </div>
  <div class="chevron-block bottom-chevron" *ngIf="!isMenuOk" (click)="toggleBottomMenu()">
    <ion-icon name="chevron-down-outline" class="chevron-icon"></ion-icon>
  </div>
</div>