import { Component, Input, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { urlPathConstants } from 'src/app/utilities/constant';

@Component({
  selector: 'app-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.scss'],
})
export class ImageGalleryComponent implements OnInit {

  @Input() item: any;
  @Input() showRadioButton: boolean;
  public imageUrl: string;
  public defaultMachineImage = urlPathConstants.defaultMachineUrl;
  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.imageUrl = this.item.graphUrl;
  }
  /**
   * Handles image error
   *
   * @param imageRef
   * @param defaultImage
   */
  public handleImageError(imageRef: HTMLImageElement, defaultImage) {
    imageRef.src = defaultImage;
  }
}
