import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ProjectDetailPage } from 'src/app/pages/project-management/project-detail/project-detail.page';
import { MenuService } from 'src/app/services/menu.service';
import { UserAuthService } from 'src/app/services/user-auth/user-auth.service';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements OnInit {
  @ViewChild('menuContainer') menuContainer: ElementRef;
  public menuList = [];
  public isMenuOk = false;
  public isCollapsed = true;
  private scrollStep: number;

  constructor(public userAuth: UserAuthService,
    private menuService: MenuService,
    public prjDetail: ProjectDetailPage,
    private router: Router) { }

  ngOnInit() {
    const userData = this.userAuth.getUserList();
    this.menuService.getMenu(userData.userId).subscribe(
      menu => { this.menuList = menu; },
      error => { console.error('Error fetching menu items:', error); });
    if (this.menuContainer) {
      this.calculateScrollStep();
    }

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // Collapse the menu after route change
        this.isCollapsed = true;
      }
    });
  }

  /**
   * Toggles menu
   */
  public toggleMenu() {
    this.isCollapsed = !this.isCollapsed;
  }


  /**
   * Toggles top menu
   */
  public toggleTopMenu() {
    this.calculateScrollStep();
    this.menuContainer.nativeElement.scrollBy({
      top: -this.scrollStep,
      behavior: 'smooth'
    });
    setTimeout(() => {
      this.isMenuOk = false;
    }, 200);
  }

  /**
   * Toggles bottom menu
   */
  public toggleBottomMenu() {
    this.calculateScrollStep();
    this.menuContainer.nativeElement.scrollBy({
      top: this.scrollStep,
      behavior: 'smooth'
    });
    setTimeout(() => {
      this.isMenuOk = true;
    }, 200);
  }

  /**
   * Calculates scroll step
   */
  private calculateScrollStep(): void {
    const firstMenuItem = this.menuContainer.nativeElement;
    if (firstMenuItem) {
      const menuItemHeight = firstMenuItem.clientHeight;
      if (menuItemHeight) {
        // Calculate scrollStep based on menu item height
        this.scrollStep = menuItemHeight;
      }
    }
  }

/**
 * Clears up
 */
  private clearUp() {
    this.prjDetail.cleanUpSegment();
  }
}
