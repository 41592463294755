import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ShareValuesService } from 'src/app/services/sharedValues/share-values.service';
import { GridConfig } from '../../../../shared/grid-config'
import { AppGridComponent } from '../../../../shared/grid/grid-component/grid-component';
import { ColDef } from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';
import { BackendCallService } from 'src/app/services/backend-call/backend-call.service';
import { isNotEmptyArray } from 'src/app/utilities/utils';
@Component({
  selector: 'app-configure-roadmap',
  templateUrl: './configure-roadmap.component.html',
  styleUrls: ['./configure-roadmap.component.scss'],
})
export class ConfigureRoadmapComponent implements OnInit {

  @ViewChild(AppGridComponent) gridComponent: AppGridComponent;
  @Input() machineId: any;
  gridColumnDefs: ColDef[];
  gridConfigItems: GridConfig;
  public isGridDisplayed = true;
  public roadmaps: any = [{}];
  private templateCount = 30;
  private increasedTemplateCount = 0;
  private similarMachineCount = 30;
  private increasedSimilarMachineCount = 0;
  public  searchText = '';
  public showKebabIcon : boolean;
  private unfilteredData: any = [{}];
  constructor(
    private modalController: ModalController,
    public sharedValue: ShareValuesService,
    public translate: TranslateService,
    public backendService: BackendCallService
  ) {
    this.getRoadmapFromTemplate();
    this.getRoadmapsFromSimilarMachine();
  }

  ngOnInit() {
    this.fnInitialiseGrid();
  }

  private getRoadmapFromTemplate() {
    const payload ={
      count: this.templateCount,
      increasedCount: this.increasedTemplateCount,
      search: '',
      isTemplate:true
    };
    this.backendService.getRoadmapListWithIndex(payload).subscribe((data: any) => {
      this.roadmaps = data;
      this.unfilteredData = data;
      console.log("this.roadmaps", this.roadmaps)
      this.roadmaps.map(item => {
        return { ...item, selected : false };        
      });
      setTimeout(() => {
        this.gridComponent?.refreshGridData();
      }, 1000);
    },(err) => {
    });
  }

  private getRoadmapsFromSimilarMachine() {
    const payload ={
      count: this.similarMachineCount,
      increasedCount: this.increasedSimilarMachineCount,
      search: '',
      machine_id: this.machineId
    };
    this.backendService.getRoadmapsFromSimilarMachine(payload).subscribe((data: any) => {
      console.log("this.getRoadmapFromSimilarMachine", data)
      setTimeout(() => {
        this.gridComponent?.refreshGridData();
      }, 1000);
    },(err) => {
    });
  }

  /**
   * To initialize roadmap grid
   */
  private fnInitialiseGrid() {
    this.fnCreateColumnDefs();
    this.fnGetGridConfigItems();
    setTimeout(() => {
      this.gridComponent.initializeGrid(false);
    }, 1000);
  }

  private fnCreateColumnDefs() {
    this.gridColumnDefs = [
      {
        headerName: this.translate.instant('Roadmap'),
        field: 'roadmapName',
        minWidth: 150,
        sortable: true,
        suppressMenu: true,
        cellClass:'grid-column-value grid-hover-link'
      },
      {
        headerName : this.translate.instant('Created By'),
        field: 'createdBy',
        minWidth : 150,
        sortable: true,
        suppressMenu: true,
        cellClass:'grid-column-value'
      },
      {
        headerName : this.translate.instant('Created Date'),
        field: 'createdAt',
        minWidth: 250,
        sortable: true,
        suppressMenu: true,
        cellClass:'grid-column-value'
      }      
    ];
  }

  private fnGetGridConfigItems() {
    this.gridConfigItems = new GridConfig(
      'id',
      'id',
      true,
      false,
      false,
      false,
      'desc',
      40,
      'no_data_available',
      this.translate.instant('Select Roadmaps'),
      '',
      this.translate.instant('New Roadmap'),
      true,
      1,
      'grid-wrapper-height-30',
      '',
      '',
      '',
      ''
    );
  }

  /**
   * To invoke operation on cell click
   *
   * @param params
   */
  checkedRoadMapList : any = [];
  public fnOnGridCellClick(params) {
    if (params.column.colId === "selected") {
      this.fnGetCheckedItemList();
      return;
    }
  }

  fnGetCheckedItemList() {
    this.checkedRoadMapList = [];

    for (var i = 0; i < this.roadmaps.length; i++) {
      if (this.roadmaps[i].selected)
        this.checkedRoadMapList.push(this.roadmaps[i]);
    }
  }

  /**
   * Closes modal on clicking close icon
   */
  public closeModal() {
    this.modalController.dismiss({
      isActionConfirmed: false
    });
  }

  /**
   * Confirms confirmation modal component
   */
  public configure() {
    this.modalController.dismiss({
      isConfigureRoadmap: true,
      selectedPredefinedRoadmapLists: this.checkedRoadMapList,
      dismissed: true,
    });
  }
  /**
   * News roadmap
   */
  public newRoadmap() {
    this.modalController.dismiss({
      isNewRoadmap: true,
      dismissed: true,
    });
  }

  /**
   * Determines whether grid search text change on change
   * 
   * @param searchText 
   */
  public onGridSearchTextChange(searchText) {
    const searchValue = searchText.detail.value.toLowerCase();
    // Check if search value is empty
    if (searchValue.trim() === '') {
      // Reset to unfiltered data if search text is empty
      this.roadmaps = [...this.unfilteredData];
    } else {
      // Filter roadmaps based on search criteria
      const filteredList = this.unfilteredData.filter(roadmap =>
        roadmap.roadmapName.toLowerCase().includes(searchValue) ||
        roadmap.createdBy.toLowerCase().includes(searchValue) ||
        roadmap.createdAt.toLowerCase().includes(searchValue)
      );
      this.roadmaps = [...filteredList];
    }
    setTimeout(() => {
      this.gridComponent?.refreshGridData();
    }, 500);
  }

  /**
   * Opens status popover
   * 
   * @param event 
   */
  public openStatusPopover(event) {
    // ToDo
  }
}
