<div class="roadmap-container" *ngIf="!showHazardDetails">
  <div class="roadmap-left-item">
    <ion-label class="roadmap-name-label"> {{'Roadmap Name' | translate }}*</ion-label>
    <ion-input type="text" class="roadmap-name-input"  name="roadmapName" placeholder="{{'Enter Roadmap Name'|translate}}"
      [(ngModel)]="roadmapName" (ionChange)="changeRoadmapName($event)">
    </ion-input>
  </div>
  <div class="roadmap-right-item">
    <ion-button class="app-btn app-btn-margin margin-top-44" shape="round" 
    fill="outline" color="primary" (click)="onSave()" [disabled]="isSaveDisabled">
      {{'Save'|translate}}
    </ion-button>
    <ion-button class="app-btn app-btn-margin margin-top-44" shape="round" 
      fill="outline" color="primary" (click)="showRoadmapGrid()">
      {{'Exit'|translate}}
    </ion-button>
  </div>
</div>
<div class="section-container" *ngIf="!showHazardDetails">
  <div class="section-left-item">
    <div class="sidebar">
      <div class="header">
        <span>{{ 'All_Sections' | translate}}</span>
        <img tooltip="{{ 'Add Section' | translate }}" placement="bottom" (click)="addSection()"
          src="/assets/images/add-circle-outline.svg" alt="" class="add-btn">
      </div>
      <div *ngIf="sections?.length == 0">
        <img  src="/assets/images/Dynamic wizard.svg" alt="" class="empty-section-img">
      </div>
      <div *ngIf="sections?.length > 0" class="scroll-container">
        <ul class="scrollable-list">
          <li *ngFor="let section of sections; let i = index" (click)="selectSection(i)"
            [class.selected]="i === selectedIndex">
            <input class="section-block" placeholder="Add Section Name" type="text" [(ngModel)]="section.sectionName" (input)="onSectionNameChanged(this)">
            <img tooltip="{{ 'Remove' | translate }}" (click)="deleteConformation(section, i, $event, 'section')" placement="bottom"
              src="/assets/images/remove-circle-outline.svg" alt="" class="remove-icon"
              [class.selected-remove]="i === selectedIndex">
          </li>
        </ul>
      </div>
      </div>
  </div>
  <div class="section-right-item">
    <ion-card class="roadmap-card">
      <div class="subsection-right-item" *ngIf="selectedSection != undefined">
        <ion-button class="app-large-btn margin-right-16" [disabled]="selectedSection.sectionName == ''" shape="round" fill="outline" color="primary"
         (click)="addNewSection()">
          {{ 'New Subsection' | translate }}
          <ion-icon slot="start" color="primary" name="add-circle-outline"></ion-icon>
        </ion-button>
      </div>
      <div *ngIf="subSections.length == 0" class="empty-subsection">
        <img src="/assets/images/Group 20605.svg" alt="empty-subsection" class="empty-subsection-img">
      </div>
      <div class="subsection-block " *ngIf="subSections.length > 0">
        <div class="subsection-container" *ngFor="let subSection of subSections; let i = index">
          <!-- Render the subsection name -->
          <div class="subsection-left-item">
          <div class="subsection-name">
            <input
              id="subsection"
              type="text"
              placeholder="Enter Subsection Name here"
              [(ngModel)]="subSection.subSectionName"
              class="subsection-input"
            />
          <ion-icon slot="end" color="primary" (click)="deleteConformation(subSection, i, $event, 'subSection')" name="trash-outline" class="trash-icon "></ion-icon>
          </div>
        </div>
      
        <!-- Render steps only if they exist for the subsection -->
        <div class="accordion-card" *ngFor="let step of subSection.steps; let j = index">
          <ion-card class="accordion-block">
            <ion-item button (click)="toggleAccordion(step, j)">
              <ion-label>{{ step.stepBody }}</ion-label>
              <ion-icon slot="end" [name]="step.accordionOpen ? 'chevron-up' : 'chevron-down'"></ion-icon>
              <ion-button class="app-large-btn margin-12 status-btn" shape="round" *ngIf="step.answers.length > 0 && step.answers[0]?.selectedValue !=''" [ngClass]="step.answers[0]?.selectColorClass">
              {{fnGetOptionName(step.answers[0]?.selectedValue) | translate}}
              </ion-button>
            </ion-item>
        
            <!-- Render the content when the accordion is open -->
            <div *ngIf="step.accordionOpen" class="accordion-content">
              <div class="question-container">
                <ion-label>{{ 'Question' | translate }} *</ion-label>
                <ion-item lines="none">
                  <ion-textarea placeholder="{{ 'Add a Question' | translate }}" [(ngModel)]="step.stepBody">
                  </ion-textarea>
                </ion-item>
              </div>
        
              <ion-list class="radio-buttons-row" [class.disabled]="fromLibrary">
                <ion-radio-group [ngModel]="getAnswer(step)"
                (ionChange)="onRadioSelectionChanged($event,step)"
                 name="compliance">
                  <div class="radio-item" *ngFor="let option of complianceOptions">
                    <ion-radio slot="start" [value]="option.value"></ion-radio>
                    <ion-label class="compliance-label">{{ option.label | translate }}</ion-label>
                  </div>
                </ion-radio-group>
              </ion-list>
        
              <div class="question-container" [class.disabled]="fromLibrary">
                <ion-label>{{ 'Notes' | translate }}</ion-label>
                <ion-item lines="none">
                  <ion-textarea name="notes" [(ngModel)]="step.notes"></ion-textarea>
                  <ion-icon *ngIf="step.padLock" class="lock-icon"name="lock-closed-outline"  color="success" (click)="step.padLock=false"></ion-icon>
                  <ion-icon *ngIf="!step.padLock" class="lock-icon" name="lock-open-outline"  color="danger"   (click)="step.padLock=true"></ion-icon>
                </ion-item>
              </div>
              <div class="button-container">
                <ion-button *ngIf="step.answers[0]?.selectedValue == 'hazard'" class="app-large-btn float-left margin-top-12" shape="round" fill="outline" color="primary"
                  (click)="addHazardDetails(j,step,subSection,selectedSection)">
                  {{ 'Add Hazard Details' | translate }}
                </ion-button>
                <div *ngIf="step?.answers[0]?.hazardDetails?.length>0">
                  <ion-label class="hazards">{{ 'Hazards' | translate }}</ion-label>
                  <ion-row *ngFor="let hazard of step?.answers[0]?.hazardDetails; let i=index">
                    <ion-col size="11" (click)="editHazard(hazard,step)">
                      <ion-card class="list-card ion-no-margin">
                        <ion-row >
                          <ion-col >
                            <ion-label class="hazard-name">{{hazard.name}}</ion-label>
                          </ion-col>                  
                        </ion-row>
                      </ion-card>
                    </ion-col>
                    <ion-col size="1">
                      <ion-icon class="trash-icon delete-icon-transition" color="primary" (click)="deleteRoadmapConfirmation(roadmap,i)" tooltip="{{'Delete roadmap' | translate }}"
                        placement="bottom" name="trash-outline">
                      </ion-icon>
                    </ion-col>
                  </ion-row>
              </div>
              </div>
              <!-- Commented for now -->
              <!-- <div class="image-block">
                <div class="upload-images">
                  <span>{{ 'Upload Images' | translate }}</span>
                  <label class="upload-label">
                    <input class="input-machine-image" id="machineImageInput" #machineImage type="file" accept=".png,.jpeg,.jpg"
                      multiple (change)="inputMachineImg($event.target.files, $event, step, i, complaintImage)">
                    <label for="machineImageInput" class="custom-upload-button">
                      <ion-icon slot="start" class="upload-icon" name="add-circle-outline"></ion-icon>
                    </label>
                  </label>
                </div>
                <div class="hazard-block" *ngIf="previewMachineImages.length > 0">
                  <div class="carosal-block">
                    <div class="ra-carousel-container">
                      <div class="ra-carousel-inner">
                        <div class="ra-carousel-item" *ngFor="let image of previewMachineImages;let i=index;">
                          <div class="ra-carousel ra-chip">
                            <div class="ra-chip-img">
                              <img src="{{ image.fileReaderResult }}" alt="machine-image" class="machine-image">
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="carousel-button ra-carousel-btn ra-prev-btn" *ngIf="previewMachineImages.length > 4" (click)="prevSlide()">
                        <img src="assets/icon/left-arrow.png" alt="left-arrow" class="arrow-icon">
                      </div>
                      <div class="carousel-button ra-carousel-btn ra-next-btn" *ngIf="previewMachineImages.length > 4" (click)="nextSlide()">
                        <img src="assets/icon/right-arrow.png" alt="right-arrow" class="arrow-icon">
                      </div>
                    </div>
                  </div>
                 
                </div>
              </div> -->
              </div>
          </ion-card>
          <ion-icon slot="end" color="primary" (click)="deleteConformation(step, j, $event, 'step')" name="trash-outline" class="trash-icon "></ion-icon>
        </div>
        <div>
          <ion-button class="app-btn margin-right-16" shape="round" fill="outline" color="primary" (click)="addNewStep(i,subSection)">
            {{ 'New Step' | translate }}
            <ion-icon slot="start" color="primary" name='add-circle-outline'></ion-icon>
          </ion-button>
        </div>
      </div>
      </div>           
    </ion-card>
  </div>
</div>
<app-add-hazard-details [showHazardDetails]="showHazardDetails" [sections]="sections"
(exitHazardDetails)="fnExitHazardDetails($event)"></app-add-hazard-details> 
