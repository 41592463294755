<ion-content>
  <div class="header-block">
    <div class="title">
      <span>{{type | translate}}</span>
    </div>
    <div class="right-block">
      <ion-searchbar mode='ios' [(ngModel)]="searchTerm" [showCancelButton]="'never'" (ionChange)="getItems($event)">
      </ion-searchbar>
      <div class="close-icon" (click)="close()">
        <img tooltip="{{'Close' | translate }}" alt="" placement="bottom" src="/assets/icon/cancel.svg">
      </div>
    </div>
  </div>

  <div class="search-results" #infScroller infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="1000"
    (scrolled)="onScroll()" [scrollWindow]="false" [immediateCheck]="true" [alwaysCallback]="true">
    <ion-row>
      <ion-col size='4' *ngFor="let each of initialHazardsList">
        <ion-card (click)="selectedData = each" [ngClass]="{'border-style' : selectedData == each}">
          <p>
            {{each.value | ellipsis: 160}}
          </p>
        </ion-card>
      </ion-col>
    </ion-row>
  </div>
</ion-content>
<ion-footer>
  <ion-row class="button-row">
    <ion-button class="app-btn" shape="round" fill="outline" color="primary" [disabled]="!selectedData"
      (click)="save()">
      {{'Pick'|translate}}
    </ion-button>
  </ion-row>
</ion-footer>