import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ShareValuesService } from '../../../../services/sharedValues/share-values.service';
import { TranslateService } from '@ngx-translate/core';
import { BackendCallService } from '../../../../services/backend-call/backend-call.service';
import { ToastService } from 'src/app/services/toast.service';
import { SubSection } from '../../service-roadmaps/roadmap-interface';
import { AppConstants } from 'src/app/utilities/constant';
import { ActivatedRoute, NavigationExtras } from '@angular/router';
import { LanguageSupportService } from 'src/app/services/language-support.service';
import { addUniqueEntry,isNotEmptyArray } from '../../../../utilities/utils';
import { UserAuthService } from 'src/app/services/user-auth/user-auth.service';
import { ConfirmationModalComponent } from 'src/app/components/confirmation-modal/confirmation-modal.component';
import { GraphService } from 'src/app/services/graph/graph.service';
interface RoadmapSection {
  title: string;
  content: string;
}
interface RoadmapSectionDetails {
  createdDate: string;
  id: string;
  sectionId: string;
  sectionName: string;
  SubSections?: SubSection[]
}
@Component({
  selector: 'roadmap-view-create',
  templateUrl: './roadmap-view-create.component.html',
  styleUrls: ['./roadmap-view-create.component.scss'],
})
export class RoadmapViewCreateComponent implements OnInit,OnChanges {
public sections = [];
public selectedSection: RoadmapSection;
public selectedIndex: number = 0;
@Input() fromLibrary : boolean = false;
@Input() roadmapName : string;
@Input() roadmapData: any = {};
@Input() roadmapId: string;
@Input() serviceMachineId : string = '';
@Input() project_id : string = '';
@Input() isCustom: boolean;
@Input() isMachineBased: boolean;
@Output() addHazardDetail = new EventEmitter<boolean>();
@Output() editHazardDetails = new EventEmitter<boolean>();
selectedLanguage: string = this.serviceMulti.getDefaultLanguage();
public accordionOpen = false;
public question: any;
public notes: any;
public compliance: any;
public subSections = [];
public sectionId : number;
public roadmapStatus: number;
public isRiskReassessment: boolean;
@Output() exitEvent = new EventEmitter<boolean>();
public reviewImageSrc: string | ArrayBuffer;
public previewMachineImages: any[]=[];
public imageFiles: File[];
public currentIndex = 0;
public hazardImages = [];
complianceOptions = [
  { value: 'yesCompliant', label: 'Yes, Compliant' },
  { value: 'notCompliant', label: 'Not Compliant' },
  { value: 'notApplicable', label: 'Not applicable' }
];
hazardRatingList = [
  { listName: 'Probability of Occurrence', listNameView: this.selectedLanguage == 'en' ? 'Likelihood Of Occurences(LO)' : 'Eintrittswahrscheinlichkeit (LO)', val: 'PO', weightagesList: this.sharedValue.weightagesList, dataChrn: {}, dataIhrn: {}, dataRiskChrn: {}, dataRiskIhrn: {}, rating: '' },
  { listName: 'Frequency of Exposition', listNameView: this.selectedLanguage == 'en' ? 'Frequency Of Exposure(FE)' : 'Häufigkeit der Gefährdung(FE)', val: 'FE', weightagesList: this.sharedValue.FEweightagesList, dataChrn: {}, dataIhrn: {}, dataRiskChrn: {}, dataRiskIhrn: {}, rating: '' },
  { listName: 'Degree of Severity', listNameView: this.selectedLanguage == 'en' ? 'Degree Of Possible Harm(DPH)' : 'Schweregrad der Verletzung (DPH)', val: 'SD', weightagesList: this.sharedValue.DPEweightagesList, dataChrn: {}, dataIhrn: {}, dataRiskChrn: {}, dataRiskIhrn: {}, rating: '' },
  { listName: 'Number of Persons Exposed', listNameView: this.selectedLanguage == 'en' ? 'Number of Persons at Risk(NPE)' : 'Anzahl der gefährdeten Personen (NPE)', val: 'NP', weightagesList: this.sharedValue.NPEweightagesList, dataChrn: {}, dataRiskChrn: {}, dataRiskIhrn: {}, dataIhrn: {}, rating: '' }];
private userInfo: {
  name: string; userId: string; email: string; jobTitle: string; isGlobalAdmin: boolean; profileData: {}; permissions: any[]; // this.initailizeFroala();
}; 
imageSrc: any;
queryParams: any={};
machineImagesList = [];
hazardImagesList = [];
public isSaveDisabled = true;

constructor(
  private modalController: ModalController,
  public sharedValue: ShareValuesService,
  public translate: TranslateService,
  public backendService: BackendCallService,
  private toastService: ToastService,
  private serviceMulti: LanguageSupportService,
  public userAuthService: UserAuthService,
  private graphService: GraphService,
  private route: ActivatedRoute,
  public sharedService: ShareValuesService, 

) {
  this.route.queryParams.subscribe(async (params) => {
    this.queryParams=params;
    console.log(this.queryParams,'asjnadajndasdkjbn')
  });
  console.log(this.serviceMachineId,'serviceMachineId');
}

ngOnChanges(changes: SimpleChanges): void {
  console.log(this.roadmapData);
  if(typeof this.roadmapData !== 'undefined')
  {  
    console.log('Inside new roadmap changes');  
    this.sections = this.roadmapData?.section ? this.roadmapData?.section : [];
    this.sections?.forEach(section => {       
    section.subSections?.forEach(subSection => {    
      subSection.subSectionId = subSection.subSection_Id;      
      subSection.steps.forEach(step => {
        step.accordionOpen = false;
        step.stepId = step.id;
        if(typeof step.answers !== 'undefined')
        {
          step.answers.forEach(element => {
            step.compliant = element.yesComplaint;
            step.notCompliant = element.notCompliant;
            step.hazard = element.hazard;
            step.notApplicable = element.notApplicable; 
            
            if(element.hazardDetails?.length > 0)
            {
              element.hazardDetails.forEach(hazard => {
                step.existingHazardDetails = [];
                step.existingHazardDetails.push({hazardName : hazard.name, id : hazard.id});
              });              
            }
            step.hazardDetails = {};
            
            this.setRadioButtonSelection(element);
          });
        }
        else
        {
            step.compliant = false;
            step.notCompliant = false;
            step.hazard = false;
            step.notApplicable = false; 
            step.answers = [];
            step.existingHazardDetails = []; 
            step.hazardDetails = {};
        }
        });
      });
    });
    this.selectSection(0);
    console.log('on changes',this.sections);
  }
}

showRoadmapGrid()
{
  this.exitEvent.emit(true);
}

ngOnInit() {
  this.userInfo = this.userAuthService.getUserList();
  if (this.isMachineBased) {
    this.complianceOptions.push({ value: 'hazard', label: 'Report Hazard' })
  }
}

deleteRoadmapConfirmation(hazaradId, i)
{

}

public changeRoadmapName(ev) {
  this.isSaveDisabled = false;
}

public addSection() {
  const newSection: any = {
    sectionId: '',
    sectionName: '',
    subSections: []
  };
  this.isSaveDisabled = false
  this.sections.push(newSection);
  this.subSections = this.sections[this.sections.length-1].subSections;
  this.selectSection(this.sections.length - 1);
  console.log(this.sections, 'added .sections')
}

/**
 * Deletes section
 * 
 * @param section section
 * @param index index
 * @param event event
 */
public deleteSection(section, index: number, event: Event) {
  event.stopPropagation();
  this.selectedSection = null;
  this.selectedIndex = null;
  let sectionId: any;
  if(section.sectionId){
    this.sections.forEach((element, i) => {
      if (element.sectionId == section.sectionId) {
        sectionId = element.sectionId;
        this.sections.splice(index, 1);
      }
    });
    if(this.fromLibrary)
    {
      const payload = {
        type: 'section',
        id: section.sectionId
      };
      this.backendService.deleteTemplateRoadmap(payload).subscribe(
        (data: any) => {
          if (data.statusCode == 200) {
            this.toastService.presentToast('Section Deleted Successfully', 4000, AppConstants.SUCCESS_SNACKBAR, 'top');
            console.log('Selected Index',index)
            this.selectSection(index- 1);  
          } else {
            this.toastService.presentToast('Failed to Delete Section', 4000, AppConstants.ERROR_SNACKBAR, 'top');
          }          
        },
        err => {
          this.toastService.presentToast(err.message, 4000, AppConstants.ERROR_SNACKBAR, 'top');
        }
      );
    }
    else
    {
    const payload = {
      id: this.roadmapId,
      serviceMachineId: this.serviceMachineId,
      projectId: this.project_id,
      section: [{
        sectionId: sectionId,
        isDelete: true,
        subSection: [{
          subSection_Id: '',
          isDelete: false,
          steps: [{
            id: '',
            isDelete: false
          }]
        }]
      }]
    };
    this.backendService.deleteRoadmapSectionsData(payload).subscribe(
      (data: any) => {
        this.toastService.presentToast(data, 4000, AppConstants.SUCCESS_SNACKBAR, 'top');
      },
      err => {
        this.toastService.presentToast(err.message, 4000, AppConstants.ERROR_SNACKBAR, 'top');
      }
    );
   }
  }else {
    this.sections.splice(index, 1);
  }

}

/**
 * Selects section
 * 
 * @param index 
 */
public selectSection(index: number) {
  this.selectedIndex = index;
  this.selectedSection = this.sections?.[index] ?  this.sections?.[index] : null;
  this.subSections = this.sections?.[index]?.subSections;
  console.log('Selected Index',this.selectedSection);  
}

onSectionNameChanged(this){}

onSubSectionNameChanged(this){}

onNotesChanged(this){}

onStepBodyChanged(this){}

onRadioSelectionChanged(event: any,step) {
  const selectedValue = event.detail.value;
  let selectedOption = this.complianceOptions.find(option => option.value === selectedValue).label;
  step.compliant = false;
  step.notCompliant = false;
  step.notApplicable = false;    
  step.hazard = false;    
  if (selectedOption == 'Yes, Compliant') { 
    this.isSaveDisabled = false;
    if (step?.answers?.length === 0) {
      step?.answers?.push({selectColorClass: 'btn-primary', selectedValue: 'yesCompliant'});
    } else {
      step.answers[0].selectColorClass = 'btn-primary';
      step.answers[0].selectedValue = 'yesCompliant';
    }
    step.compliant = true; 
  } else if(selectedOption == 'Not applicable') {
    this.isSaveDisabled = false;
    if (step?.answers?.length === 0) {
      step?.answers?.push({selectColorClass: 'btn-primary', selectedValue: 'notApplicable'});
    } else {
      step.answers[0].selectColorClass = 'btn-primary';
      step.answers[0].selectedValue = 'notApplicable';
    }
    step.notApplicable = true;
  } else if(selectedOption =='Report Hazard') {
    this.isSaveDisabled = false;
    if (step?.answers?.length === 0) {
      step?.answers?.push({selectColorClass: 'btn-danger', selectedValue: 'hazard'});
    } else {
      step.answers[0].selectColorClass = 'btn-danger';
      step.answers[0].selectedValue = 'hazard';
    }
    step.hazard = true;  
  } else  if (selectedOption == 'Not Compliant') {
    if (step?.stepNote==null || step?.stepNote == '') {
      this.isSaveDisabled = true;
    } else {
      this.isSaveDisabled = false;
    }
    if (step?.answers?.length === 0) {
      step?.answers?.push({selectColorClass: 'btn-default', selectedValue: 'notCompliant'});
    } else {
      step.answers[0].selectColorClass = 'btn-default';
      step.answers[0].selectedValue = 'notCompliant';
    }
    step.notCompliant = true;
  }
  console.log('Updated section',this.sections);
}
showHazardDetails: boolean;
addHazardDetails(index, step,subSection,section) 
{
  console.log(section);
  console.log(subSection);
  if (step.stepBody) {
    const data = { sectionName: section.sectionName, subSectionName: subSection.subSectionName, index, roadMapName: this.roadmapName, question: step.stepBody };
    const navigationExtras: NavigationExtras = {
      queryParamsHandling: 'merge',        
    };
    const hazardInfo = {
      sectionData: data,
      hazardData: step,
      isCustom:this.isCustom,
      roadmapStatus: this.roadmapStatus,
      isRiskReassessment: this.isRiskReassessment,
      addNewHazard: true,
      serviceMachineId:this.serviceMachineId
    };
    // Store the parameter in sessionStorage
    sessionStorage.setItem('hazardInfo', JSON.stringify(hazardInfo));
    this.showHazardDetails=true;
    //this.router.navigate([window.location.pathname, 'add-hazard-details'],navigationExtras);
  } else {
    this.toastService.presentToast('Please complete all the mandatory Fields', 4000, AppConstants.ERROR_SNACKBAR, 'top');
  }

}

  /**
   * To enable save button on changing note text
   * @param ev 
   * @param step 
   */
  public onNoteChange(ev, step) {
    if(ev?.detail?.value && step?.notCompliant == true) {
      this.isSaveDisabled = false;
      step.stepNote = ev?.detail?.value;           
    }
    else if(step?.notCompliant == true && (step.stepNote == null || step.stepNote == ''))
    {
      this.isSaveDisabled = true;
    }
}

setRadioButtonSelection(element) {
  if (element.yesComplaint) {
    element.selectedValue = 'yesCompliant';
    element.selectColorClass = 'btn-primary';
  } else if (element.notApplicable) {
    element.selectedValue = 'notApplicable';
    element.selectColorClass = 'btn-primary';
  } else if (element.hazard) {
    element.selectedValue = 'hazard';
    element.selectColorClass = 'btn-danger'
  } else if (element.notCompliant) {
    element.selectedValue = 'notCompliant';
    element.selectColorClass = 'btn-default';
  } else {
    element.selectedValue = '';
    element.selectColorClass = ''
  } 

}


hazardCheckLists: any;
editHazard(eachHazard, step) {
  this.backendService.getFourEyeQualityHazardCheckListsInfo(eachHazard.id).then((res: any) => {
    if (res) {
      this.hazardCheckLists = res;
      this.constructHazardHrnStructure(eachHazard, step);
    }
  }).catch(err => {
    console.log('getting issue in fetching four eye quality hazard checklist info :', err);
  });

}

async constructHazardHrnStructure(eachHazard, step) {
  if (isNotEmptyArray(this.hazardCheckLists.hrnCalculations)) {
    const machineModes = [];
    const machineModesWithIds = [];
    const riskmachineModes = [];
    const riskMachineModesWithIds = [];
    this.hazardCheckLists?.hrnCalculations.forEach(_hrn => {
      if (_hrn.type == 'Current') {
        if (isNotEmptyArray(_hrn.values)) {_hrn.values.forEach(_val => addUniqueEntry(machineModes, _val.mode));}
        if (isNotEmptyArray(_hrn.values)) {_hrn.values.forEach(_val => addUniqueEntry(machineModesWithIds, { mode: _val.mode, id: _val.id, type: _hrn.type }));}
      }
      if (_hrn.type == 'Risk Reassessment Current') {
        if (isNotEmptyArray(_hrn.values)) {_hrn.values.forEach(_val => addUniqueEntry(riskmachineModes, _val.mode));}
        if (isNotEmptyArray(_hrn.values)) {_hrn.values.forEach(_val => addUniqueEntry(riskMachineModesWithIds, { mode: _val.mode, id: _val.id, type: _hrn.type }));}
      }
    });
    this.hazardCheckLists.hrnCalculations.forEach(_hrn => {
      if (_hrn.type == 'Current' || _hrn.type == 'current') {
        this.hazardRatingList.forEach(_hr => {
          machineModes.forEach(_mode => {
            _hrn.values.forEach(_val => {
              if (_mode == _val.mode) {
                const rating = _val.rating;//+_val.po * +_val.sd * +_val.fe * +_val.np;
                const body = {
                  label: '',
                  value: '',
                  id: '',
                  rating
                };
                if (_hr.val == 'PO') {
                  if (_val.po == '1') {
                    body.value = '0.033';
                  }
                  else if (_val.po == '2') {
                    body.value = '0.5';
                  }
                  else if (_val.po == '3') {
                    body.value = '1';
                  }
                  else if (_val.po == '4') {
                    body.value = '2';
                  }
                  else if (_val.po == '5') {
                    body.value = '5';
                  }
                  else if (_val.po == '6') {
                    body.value = '8';
                  }
                  else if (_val.po == '7') {
                    body.value = '10';
                  }
                  else if (_val.po == '8') {
                    body.value = '15';
                  }
                }
                else if (_hr.val == 'SD') {
                  if (_val.sd == '1') {
                    body.value = '0.1';
                  }
                  else if (_val.sd == '2') {
                    body.value = '0.5';
                  }
                  else if (_val.sd == '3') {
                    body.value = '1';
                  }
                  else if (_val.sd == '4') {
                    body.value = '2';
                  }
                  else if (_val.sd == '5') {
                    body.value = '4';
                  }
                  else if (_val.sd == '6') {
                    body.value = '8';
                  }
                  else if (_val.sd == '7') {
                    body.value = '15';
                  }
                }
                else if (_hr.val == 'FE') {
                  if (_val.fe == '1') { body.value = '0.1'; }
                  else if (_val.fe == '2') { body.value = '0.2'; }
                  else if (_val.fe == '3') { body.value = '1'; }
                  else if (_val.fe == '4') { body.value = '1.5'; }
                  else if (_val.fe == '5') { body.value = '2.5'; }
                  else if (_val.fe == '6') { body.value = '4'; }
                  else if (_val.fe == '7') { body.value = '15'; }
                }
                else if (_hr.val == 'NP') {
                  if (_val.np == '1') { body.value = '1'; }
                  else if (_val.np == '2') { body.value = '2'; }
                  else if (_val.np == '3') { body.value = '4'; }
                  else if (_val.np == '4') { body.value = '8'; }
                  else if (_val.np == '5') { body.value = '12'; }
                }


                if (_hr.val == 'PO') {body.id = _val.po;}
                else if (_hr.val == 'SD') {body.id = _val.sd;}
                else if (_hr.val == 'FE') {body.id = _val.fe;}
                else if (_hr.val == 'NP') {body.id = _val.np;}

                // if (_hr.val == 'PO') body.value = _val.po;
                // else if (_hr.val == 'SD') body.value = _val.sd
                // else if (_hr.val == 'FE') body.value = _val.fe
                // else if (_hr.val == 'NP') body.value = _val.np

                const matchedWeight = _hr.weightagesList.find(_wght => _wght.value == body.value);
                if (matchedWeight) {body.label = matchedWeight.label;}
                _hr.dataChrn[_mode] = body;
              }
            });
          });
        });
      } else if (_hrn.type == 'Indicative' || _hrn.type == 'indicative') {
        this.hazardRatingList.forEach(_hr => {
          machineModes.forEach(_mode => {
            _hrn.values.forEach(_val => {
              if (_mode == _val.mode) {
                const rating = _val.rating;//+_val.po * +_val.sd * +_val.fe * +_val.np;
                const body = {
                  label: '',
                  value: '',
                  id: '',
                  rating
                };

                if (_hr.val == 'PO') {
                  if (_val.po == '1') {
                    body.value = '0.033';
                  }
                  else if (_val.po == '2') {
                    body.value = '0.5';
                  }
                  else if (_val.po == '3') {
                    body.value = '1';
                  }
                  else if (_val.po == '4') {
                    body.value = '2';
                  }
                  else if (_val.po == '5') {
                    body.value = '5';
                  }
                  else if (_val.po == '6') {
                    body.value = '8';
                  }
                  else if (_val.po == '7') {
                    body.value = '10';
                  }
                  else if (_val.po == '8') {
                    body.value = '15';
                  }
                }
                else if (_hr.val == 'SD') {
                  if (_val.sd == '1') {
                    body.value = '0.1';
                  }
                  else if (_val.sd == '2') {
                    body.value = '0.5';
                  }
                  else if (_val.sd == '3') {
                    body.value = '1';
                  }
                  else if (_val.sd == '4') {
                    body.value = '2';
                  }
                  else if (_val.sd == '5') {
                    body.value = '4';
                  }
                  else if (_val.sd == '6') {
                    body.value = '8';
                  }
                  else if (_val.sd == '7') {
                    body.value = '15';
                  }
                }
                else if (_hr.val == 'FE') {
                  if (_val.fe == '1') { body.value = '0.1'; }
                  else if (_val.fe == '2') { body.value = '0.2'; }
                  else if (_val.fe == '3') { body.value = '1'; }
                  else if (_val.fe == '4') { body.value = '1.5'; }
                  else if (_val.fe == '5') { body.value = '2.5'; }
                  else if (_val.fe == '6') { body.value = '4'; }
                  else if (_val.fe == '7') { body.value = '15'; }
                }
                else if (_hr.val == 'NP') {
                  if (_val.np == '1') { body.value = '1'; }
                  else if (_val.np == '2') { body.value = '2'; }
                  else if (_val.np == '3') { body.value = '4'; }
                  else if (_val.np == '4') { body.value = '8'; }
                  else if (_val.np == '5') { body.value = '12'; }
                }

                if (_hr.val == 'PO') {body.id = _val.po;}
                else if (_hr.val == 'SD') {body.id = _val.sd;}
                else if (_hr.val == 'FE') {body.id = _val.fe;}
                else if (_hr.val == 'NP') {body.id = _val.np;}

                // if (_hr.val == 'PO') body.value = _val.po
                // else if (_hr.val == 'SD') body.value = _val.sd
                // else if (_hr.val == 'FE') body.value = _val.fe
                // else if (_hr.val == 'NP') body.value = _val.np

                const matchedWeight = _hr.weightagesList.find(_wght => _wght.value == body.value);
                if (matchedWeight) {body.label = matchedWeight.label;}
                _hr.dataIhrn[_mode] = body;
              }
            });
          });
        });
      } else if (_hrn.type == 'Risk Reassessment Current') {
        this.hazardRatingList.forEach(_hr => {
          riskmachineModes.forEach(_mode => {
            _hrn.values.forEach(_val => {
              if (_mode == _val.mode) {
                const rating = _val.rating;//+_val.po * +_val.sd * +_val.fe * +_val.np;
                const body = {
                  label: '',
                  value: '',
                  id: '',
                  rating
                };

                if (_hr.val == 'PO') {
                  if (_val.po == '1') {
                    body.value = '0.033';
                  }
                  else if (_val.po == '2') {
                    body.value = '0.5';
                  }
                  else if (_val.po == '3') {
                    body.value = '1';
                  }
                  else if (_val.po == '4') {
                    body.value = '2';
                  }
                  else if (_val.po == '5') {
                    body.value = '5';
                  }
                  else if (_val.po == '6') {
                    body.value = '8';
                  }
                  else if (_val.po == '7') {
                    body.value = '10';
                  }
                  else if (_val.po == '8') {
                    body.value = '15';
                  }
                }
                else if (_hr.val == 'SD') {
                  if (_val.sd == '1') {
                    body.value = '0.1';
                  }
                  else if (_val.sd == '2') {
                    body.value = '0.5';
                  }
                  else if (_val.sd == '3') {
                    body.value = '1';
                  }
                  else if (_val.sd == '4') {
                    body.value = '2';
                  }
                  else if (_val.sd == '5') {
                    body.value = '4';
                  }
                  else if (_val.sd == '6') {
                    body.value = '8';
                  }
                  else if (_val.sd == '7') {
                    body.value = '15';
                  }
                }
                else if (_hr.val == 'FE') {
                  if (_val.fe == '1') { body.value = '0.1'; }
                  else if (_val.fe == '2') { body.value = '0.2'; }
                  else if (_val.fe == '3') { body.value = '1'; }
                  else if (_val.fe == '4') { body.value = '1.5'; }
                  else if (_val.fe == '5') { body.value = '2.5'; }
                  else if (_val.fe == '6') { body.value = '4'; }
                  else if (_val.fe == '7') { body.value = '15'; }
                }
                else if (_hr.val == 'NP') {
                  if (_val.np == '1') { body.value = '1'; }
                  else if (_val.np == '2') { body.value = '2'; }
                  else if (_val.np == '3') { body.value = '4'; }
                  else if (_val.np == '4') { body.value = '8'; }
                  else if (_val.np == '5') { body.value = '12'; }
                }

                if (_hr.val == 'PO') {body.id = _val.po;}
                else if (_hr.val == 'SD') {body.id = _val.sd;}
                else if (_hr.val == 'FE') {body.id = _val.fe;}
                else if (_hr.val == 'NP') {body.id = _val.np;}

                // if (_hr.val == 'PO') body.value = _val.po
                // else if (_hr.val == 'SD') body.value = _val.sd
                // else if (_hr.val == 'FE') body.value = _val.fe
                // else if (_hr.val == 'NP') body.value = _val.np

                const matchedWeight = _hr.weightagesList.find(_wght => _wght.value == body.value);
                if (matchedWeight) {body.label = matchedWeight.label;}
                _hr.dataRiskChrn[_mode] = body;
              }
            });
          });
        });
      } else if (_hrn.type == 'Risk Reassessment Indicative') {
        this.hazardRatingList.forEach(_hr => {
          riskmachineModes.forEach(_mode => {
            _hrn.values.forEach(_val => {
              if (_mode == _val.mode) {
                const rating = _val.rating;//+_val.po * +_val.sd * +_val.fe * +_val.np;
                const body = {
                  label: '',
                  value: '',
                  id: '',
                  rating
                };

                if (_hr.val == 'PO') {
                  if (_val.po == '1') {
                    body.value = '0.033';
                  }
                  else if (_val.po == '2') {
                    body.value = '0.5';
                  }
                  else if (_val.po == '3') {
                    body.value = '1';
                  }
                  else if (_val.po == '4') {
                    body.value = '2';
                  }
                  else if (_val.po == '5') {
                    body.value = '5';
                  }
                  else if (_val.po == '6') {
                    body.value = '8';
                  }
                  else if (_val.po == '7') {
                    body.value = '10';
                  }
                  else if (_val.po == '8') {
                    body.value = '15';
                  }
                }
                else if (_hr.val == 'SD') {
                  if (_val.sd == '1') {
                    body.value = '0.1';
                  }
                  else if (_val.sd == '2') {
                    body.value = '0.5';
                  }
                  else if (_val.sd == '3') {
                    body.value = '1';
                  }
                  else if (_val.sd == '4') {
                    body.value = '2';
                  }
                  else if (_val.sd == '5') {
                    body.value = '4';
                  }
                  else if (_val.sd == '6') {
                    body.value = '8';
                  }
                  else if (_val.sd == '7') {
                    body.value = '15';
                  }
                }
                else if (_hr.val == 'FE') {
                  if (_val.fe == '1') { body.value = '0.1'; }
                  else if (_val.fe == '2') { body.value = '0.2'; }
                  else if (_val.fe == '3') { body.value = '1'; }
                  else if (_val.fe == '4') { body.value = '1.5'; }
                  else if (_val.fe == '5') { body.value = '2.5'; }
                  else if (_val.fe == '6') { body.value = '4'; }
                  else if (_val.fe == '7') { body.value = '15'; }
                }
                else if (_hr.val == 'NP') {
                  if (_val.np == '1') { body.value = '1'; }
                  else if (_val.np == '2') { body.value = '2'; }
                  else if (_val.np == '3') { body.value = '4'; }
                  else if (_val.np == '4') { body.value = '8'; }
                  else if (_val.np == '5') { body.value = '12'; }
                }

                if (_hr.val == 'PO') {body.id = _val.po;}
                else if (_hr.val == 'SD') {body.id = _val.sd;}
                else if (_hr.val == 'FE') {body.id = _val.fe;}
                else if (_hr.val == 'NP') {body.id = _val.np;}

                // if (_hr.val == 'PO') body.value = _val.po
                // else if (_hr.val == 'SD') body.value = _val.sd
                // else if (_hr.val == 'FE') body.value = _val.fe
                // else if (_hr.val == 'NP') body.value = _val.np

                const matchedWeight = _hr.weightagesList.find(_wght => _wght.value == body.value);
                if (matchedWeight) {body.label = matchedWeight.label;}
                _hr.dataRiskIhrn[_mode] = body;
              }
            });
          });
        });
      }
    });
    this.hazardCheckLists.machineModes = machineModes;
    this.hazardCheckLists.machineModesWithIds = machineModesWithIds;
    this.hazardCheckLists.riskmachineModes = riskmachineModes;
    this.hazardCheckLists.riskMachineModesWithIds = riskMachineModesWithIds;
    await this.goToHazrdsPage(eachHazard, step);
  } else {
    this.hazardCheckLists.machineModes = [];
    this.hazardCheckLists.machineModesWithIds = [];
    this.hazardCheckLists.riskmachineModes = [];
    this.hazardCheckLists.riskMachineModesWithIds = [];
    await this.goToHazrdsPage(eachHazard, step);
  }
}

goToHazrdsPage(eachHazard, step) {
  step.hazardTypesData = [];
  const sectionInfo = {
    sectionName: step.sectionName,
    subSectionName: step.subSectionName,
    index: 0,
    roadMapName: this.roadmapName
  };
  const hazrdInfo = {
    id: eachHazard.id,
    stepBody: '',
    stepNote: '',
    answers: null,
    hazardCount: 0,
    isListItemOpened: true,
    compliance: 'hazard',
    hazardName: this.hazardCheckLists?.name,
    hazardType: this.hazardCheckLists?.hazardType,
    hazardRatingList: this.hazardRatingList,
    machineModes: this.hazardCheckLists?.machineModes,
    machineModesWithIds: this.hazardCheckLists?.machineModesWithIds,
    riskmachineModes: this.hazardCheckLists?.riskmachineModes,
    riskMachineModesWithIds: this.hazardCheckLists?.riskMachineModesWithIds,
    pickInitialHazard: this.hazardCheckLists?.initial_hazard,
    pickInitialHazardValue: this.hazardCheckLists?.initial_hazard,
    pickControlMeasure: this.hazardCheckLists?.counter_measure,
    pickCntrlMeasureValue: this.hazardCheckLists?.counter_measure,
    determinePlrValue: this.hazardCheckLists?.plr,
    recommendedCategory: this.hazardCheckLists?.category,
    hazardImagesList: this.hazardCheckLists?.hazardMedia,
    plrObj: this.hazardCheckLists.plrObj
  };
  this.hazardCheckLists.hazardType.forEach(_ht => {
    const sourceBody = [];
    const consequeceBody = [];
    _ht.hazardSources.forEach(_hts => { sourceBody.push(_hts.source); });
    _ht.hazardConsequences.forEach(_hts => { consequeceBody.push(_hts.consequences); });
    step.hazardTypesData.push({ hazardType: _ht.hazardTypeName || _ht.type, source: sourceBody, consequences: consequeceBody });
  });
  const navigationExtras: NavigationExtras = {
    queryParamsHandling: 'merge',
  };
  const hazardInfo = {
    isEditingFourEyeQuality: true,
    sectionData: sectionInfo,
    isCustom:this.isCustom,
    hazardData: hazrdInfo,
    roadmapStatus: this.roadmapStatus,
    isRiskReassessment: this.isRiskReassessment,
    addNewHazard: false
  };
  // Store the parameter in sessionStorage
  sessionStorage.setItem('hazardInfo', JSON.stringify(hazardInfo));
  //this.router.navigate([window.location.pathname, 'add-hazard-details'],navigationExtras);
  this.showHazardDetails=true;
}


fnGetOptionName(value)
{
  let optionname = '';
  if(value != '')
    optionname = this.complianceOptions.find(it=>it.value == value)?.label == "Report Hazard" ? "Hazard Reported" : this.complianceOptions.find(it=>it.value == value)?.label;
  return optionname;
}

/**
 * Toggles accordion
 * 
 * @param step 
 * @param stepIndex 
 */
public toggleAccordion(step, stepIndex) {
  // Loop through all subsections
  this.subSections.forEach((subSection) => {
    // Check if the current subsection contains the clicked step
    console.log(this.subSections);
    if (subSection.steps.includes(step)) {
      // Loop through all steps in the current subsection
      subSection.steps.forEach((stp, i) => {
        // Toggle the clicked accordion and close all others
        stp.accordionOpen = i === stepIndex ? !stp.accordionOpen : false;
      });
    }
  });
}

/**
* Deletes subsection
* 
* @param subsection 
* @param index 
*/
public deleteSubsection(subsection, index: number) {
  let subSectionId: any;
  if(subsection.subSection_Id)  {
    this.subSections.forEach((element, i) => {
      if (element.subSection_Id == subsection.subSection_Id) {
        subSectionId = element.subSection_Id;
        this.subSections.splice(index, 1);
        return;
      }
    });
    if(this.fromLibrary)
    {
      const payload = {
        type: 'subsection',
        id: subsection.subSection_Id
      };
      this.backendService.deleteTemplateRoadmap(payload).subscribe(
        (data: any) => {
          if (data.statusCode == 200) {
            this.toastService.presentToast('SubSection Deleted Successfully', 4000, AppConstants.SUCCESS_SNACKBAR, 'top');
            } else {
            this.toastService.presentToast('Failed to Delete SubSection', 4000, AppConstants.ERROR_SNACKBAR, 'top');
          }          
        },
        err => {
          this.toastService.presentToast(err.message, 4000, AppConstants.ERROR_SNACKBAR, 'top');
        }
      );
    }
    else
    {
      const payload = {
        id: this.roadmapId,
        serviceMachineId: this.serviceMachineId,
        projectId: this.project_id,
        section: [{
          sectionId: this.sectionId,
          isDelete: false,
          subSection: [{
            subSection_Id: subSectionId,
            isDelete: true,
            steps: [{
              id: '',
              isDelete: false
            }]
          }]
        }]
      };
      this.backendService.deleteRoadmapSectionsData(payload).subscribe(
        (data: any) => {
          this.toastService.presentToast(data, 4000, AppConstants.SUCCESS_SNACKBAR, 'top');
        },
        err => {
          this.toastService.presentToast(err.message, 4000, AppConstants.ERROR_SNACKBAR, 'top');
        }
      );
    }
  }else {
    this.subSections.splice(index, 1);
  }

}

fnExitHazardDetails(_event)
{
  //this.sections = _event;
  console.log('Back from Add Hazard',_event);
  this.showHazardDetails = false;
}

/**
* Deletes step
* 
* @param step 
* @param index 
* @param event 
*/
public deleteStep(step, index: number, event: Event) {
  event.stopPropagation();
  let subSectionId: any;
  if(step.id)  {
    this.subSections.forEach(element => {
      subSectionId = element.subSection_Id;
      element.steps.forEach((eachStep, i) => {
        if (step.id === eachStep.id) {
          element.steps.splice(i, 1);
        }
      });
    });
    if(this.fromLibrary)
    {
        const payload = {
          type: 'step',
          id: step.stepId
        };
        this.backendService.deleteTemplateRoadmap(payload).subscribe(
          (data: any) => {
            if (data.statusCode == 200) {
              this.toastService.presentToast('Step Deleted Successfully', 4000, AppConstants.SUCCESS_SNACKBAR, 'top');
              } else {
              this.toastService.presentToast('Failed to Delete Step', 4000, AppConstants.ERROR_SNACKBAR, 'top');
            }            
          },
          err => {
            this.toastService.presentToast(err.message, 4000, AppConstants.ERROR_SNACKBAR, 'top');
          }
        );
    }
    else
    {
    const payload = {
      id: this.roadmapId,
      serviceMachineId: this.serviceMachineId,
      projectId: this.project_id,
      section: [
        {
          sectionId: this.sectionId,
          isDelete: false,
          subSection: [
            {
              subSection_Id: subSectionId,
              isDelete: false,
              steps: [
                {
                  id: step.id,
                  isDelete: true
                }
              ]
            }
          ]
        }
      ]
    };
    this.backendService.deleteRoadmapSectionsData(payload).subscribe(
      (data: any) => {
        this.toastService.presentToast(data, 4000, AppConstants.SUCCESS_SNACKBAR, 'top');
      },
      (err) => {
        this.toastService.presentToast(err.message, 4000, AppConstants.ERROR_SNACKBAR, 'top');
      }
    );
   }
  }else {
    this.subSections.forEach((element, i) => {
      element.steps.splice(index, 1)
    });
  }
}

/**
 * Adds new section
 */
public addNewSection() {
  const newSubSection = {
    subSection_Id: '',
    subSectionName: `Sub Section ${this.subSections.length + 1}`,
    steps: []
  };
  this.isSaveDisabled = false
  this.subSections.push(newSubSection);
  console.log('this.section',this.sections);
}

/**
 * Adds new step
 * 
 * @param subSectionIndex 
 */
public addNewStep(subSectionIndex: number,subSection) {
  const newStep = {
    id: '',
    stepBody: `New Step ${this.subSections[subSectionIndex].steps.length + 1}`,
    stepNote: `New Step ${this.subSections[subSectionIndex].steps.length + 1} note`,
    padLock: false,
    createdDate: new Date().toISOString(),
    answers : [{ selectedValue: '' ,selectColorClass: ''}],
    hazardCount: 0,
    accordionOpen: false
  };
  this.isSaveDisabled = false
  this.subSections[subSectionIndex].steps.push(newStep);
  console.log('this.subsection',this.subSections[subSectionIndex]);
  console.log('this.sections',this.sections);
}

/**
 * Gets answer
 * 
 * @param step 
 * @returns selectedValue
 */
getAnswer(step: any) {
  if (!step.answers) {
    step.answers = [{}]; // Initialize answers as an array with a default object
  }
  return step.answers[0]?.selectedValue;
}


onSave()
{
  let payload = {    
    roadmapId: this.roadmapId,
    project_Id: this.project_id,
    roadmapName: this.roadmapName,
    serviceMachineId: this.serviceMachineId || sessionStorage.getItem('selectedServiceIdNonSAP'),
    userId: this.userInfo.userId,
    isCustom: this.isCustom,
    maskAsComplete: false    
  };

  if(this.fromLibrary)
   payload['section'] = this.sections;
  else
   payload['sections'] =this.sections;

   const shouldStop = this.sections.some(section =>
    section.subSections.some(subSection =>
      subSection.steps.some(step =>
        step?.notCompliant === true && (!step?.stepNote || step?.stepNote === '')
      )
    )
  );
 
  if (shouldStop) {
    this.toastService.presentToast("Please fill in the notes for Not Compliant steps", 4000, AppConstants.ERROR_SNACKBAR, 'top');
    return;
  } 
  else
  {
  if(this.fromLibrary)
  {
    this.backendService.updateTemplateRoadmap(payload).subscribe(async (res: any) => {
      if ((res.statusCode === 200)) {     
        this.toastService.presentToast(res.message, 4000, AppConstants.SUCCESS_SNACKBAR, 'top');
      }else{
        this.toastService.presentToast(res.message, 4000, AppConstants.ERROR_SNACKBAR, 'top');
      }
      this.exitEvent.emit(true);
    }), err => {
      console.log("Machine roadmap err", err)
    }
  }
  else if(this.isMachineBased) {
    this.backendService.extendRoadmapv1(payload).subscribe(async (res: any) => {
      if ((res.statusCode === 200)) {     
        this.toastService.presentToast(res.message, 4000, AppConstants.SUCCESS_SNACKBAR, 'top');
      }else{
        this.toastService.presentToast(res.message, 4000, AppConstants.ERROR_SNACKBAR, 'top');
      }
      this.exitEvent.emit(true);
    }), err => {
      console.log("Machine roadmap err", err)
    }
  } else if(!this.isMachineBased) {
    this.backendService.extendServiceRoadmap(payload).subscribe(async (res: any) => {
      console.log("res", res)
      if ((res.statusCode === 200)) {     
        this.toastService.presentToast(res.message, 4000, AppConstants.SUCCESS_SNACKBAR, 'top');
      }else{
        this.toastService.presentToast(res.message, 4000, AppConstants.ERROR_SNACKBAR, 'top');
      }
      this.exitEvent.emit(true);
    }), err => {
      console.log("Service roadmap err", err)
    }
  }
 }
}


  /**
   * Deletes conformation
   * 
   * @param [data] data
   * @param [index] index
   * @param [event] event
   * @param [itemType] itemType
   */
  public async deleteConformation(data?, index?: number, event?: Event, itemType?: string) {
    const title = AppConstants.ARE_YOU_SURE_TO_DELETE;
    const actionText = AppConstants.DELETE;
    const props = {
      confirmationTitle: title,
      actionText
    };
    const modal = await this.modalController.create({
      component: ConfirmationModalComponent,
      cssClass: 'small-alert-modal',
      backdropDismiss: false,
      showBackdrop: false,
      componentProps: props,
    });
    await modal.present();
    const { data: { isActionConfirmed } } = await modal.onWillDismiss();
    if (isActionConfirmed) {
      if (itemType == 'section') {
        this.deleteSection(data, index, event);
      } else if (itemType == 'subSection') {
        this.deleteSubsection(data, index);
      } else if (itemType == 'step') {
        this.deleteStep(data, index, event);
      }     

    }
  }


/**
 * Shows slide
 *
 * @param [index]
 */
  public showSlide(index: number = this.previewMachineImages?.length - 1): void {
    const carouselInner = document.querySelector('.ra-carousel-inner') as HTMLElement;
    const itemWidth = document.querySelector('.ra-carousel-item')?.clientWidth || 0;
    const newPosition = -index * itemWidth;
    carouselInner.style.transform = `translateX(${newPosition}px)`;
    this.currentIndex = index;
  }

  /**
   * Next slide
   */
  public nextSlide(): void {
    const totalItems = this.previewMachineImages.length;
    this.currentIndex = (this.currentIndex + 1) % totalItems;
    this.showSlide(this.currentIndex);
  }

  /**
   * Prev slide
   */
  public prevSlide(): void {
    const totalItems = this.previewMachineImages.length;
    this.currentIndex = (this.currentIndex - 1 + totalItems) % totalItems;
    this.showSlide(this.currentIndex);
  }

  /**
   * Uploads hazard images
   */
  public async uploadHazardImages() {
    this.previewMachineImages = [];
    const file_path = `${this.queryParams.project_Id}/${this.queryParams.serviceId}/${this.queryParams.machineId}/${this.roadmapId}/`;
    const formData = new FormData();
    const hazardImageFormData = this.sharedValue.prepareImageFormData(formData, this.imageFiles, file_path)
    await this.backendService.uploadImages(hazardImageFormData).subscribe((res: any) => {
      console.log(res);
      res.forEach(element => {
        const imageData = {
          fileName: element?.file_name,
          contentType: element?.content_type,
          url: element?.url,
          image_Size: element?.file_size
        };
        this.hazardImagesList.push(imageData);
        this.previewMachineImages.push(imageData);
      })
    }, (err) => {
    });
  }

  /**
   * Inputs machine img
   * 
   * @param files 
   * @param machineImage 
   */
  public inputMachineImg(files: File[], machineImage: HTMLInputElement) {
    const self = this;
    this.imageFiles = Array.from(files);
    for (const file of files) {
      const reader = new FileReader();
      const fileSize = file.size;
      const imgData = {
        fileName: file.name,
        fileSize: file.size,
        fileReaderResult: reader.result,
        fileData: file
      };
      reader.onload = e => {
        self.reviewImageSrc = reader.result;
        imgData.fileReaderResult = reader.result;
        if (fileSize < 4194304) {
          self.previewMachineImages.push(imgData);
        } else {
          self.previewMachineImages.push(imgData);
          this.sharedValue.errorShowToast("", "Image size exceeds 4MB")
        }
      };
      reader.readAsDataURL(file);
      reader.onerror = (error) => {

      };
    }
    this.uploadHazardImages();
    machineImage.value = '';
  }
}
