<div class="flex-container" *ngIf="showHazardDetails">
  <div class="top-btn">
    <div class="complete-btn">
      <app-exit-button class="exit-left-margin-120" (click)="goBack()"></app-exit-button>
      <ion-button class="app-btn" shape="round" fill="outline" position="bottom" (click)="handleFormAction()"
        type="submit">
        {{isEditingFourEyeQuality ? 'Save' : 'Complete' | translate}}
      </ion-button>
    </div>
  </div>
</div>
  <div class="widge-container" *ngIf="showHazardDetails">
    <div class="widget-card-list">
      <form #addHazardDetails="ngForm" autocomplete="off" (ngSubmit)="complete(addHazardDetails)" novalidate>
        <ion-grid>
          <!--HAZARD NAME CARD START-->
          <ion-card class="main-card">
            <ion-card-header class="ion-header-css">{{'Hazard Name'|translate}}</ion-card-header>
            <ion-card-content>
              <div class="inp-container">
                <ion-label>{{"Add Hazard Name"| translate}}</ion-label>
                <ion-item lines=none>
                  <ion-input name="hazardName"
                    [readonly]="(this.roadmapStatus ==='Completed' && this.isCustom === 'false')|| (this.isRiskReassessment && !this.addNewHazard)"
                    placeholder='{{"Enter Hazard Name"|translate}}' [(ngModel)]="hardDetailsData.hazardName" required
                    #hazardName="ngModel">
                  </ion-input>
                </ion-item>
                <ion-text style="font-size: x-small; font-weight: bold" *ngIf="hazardName.invalid && hazardName.touched"
                  color="danger">Required
                </ion-text>
              </div>

            </ion-card-content>
          </ion-card>
          <!--HAZARD NAME CARD END-->
          <!--HAZARD TYPE CARD START-->
          <ion-card class="main-card">
            <ion-card-header class="ion-header-css">{{'Hazard Type' | translate}}</ion-card-header>
            <ion-card-content ngModelGroup="hazardTypesData">
              <ion-card class="inner-card scrollVertical" *ngFor="let newHazard of addNewHazardDetails;let i = index"
                [ngModelGroup]="''+i" style="height: 27em !important; padding-top: 4.5em !important;">
                <div class="inp-container">
                  <ion-label style="font-weight: bold;">{{"Select Hazard Type"|translate}}</ion-label>
                  <div>
                    <ng-select
                      [readonly]="(this.roadmapStatus ==='Completed' && this.isCustom === 'false')|| (this.isRiskReassessment && !this.addNewHazard)"
                      [(ngModel)]="newHazard.hazardType" placeholder="{{'Select'|translate}}" [clearable]="false"
                      [searchable]="false" dropdownPosition="bottom" class="dropdown" #hazardType="ngModel"
                      name="hazardType" (change)="selectEvent($event,i)">
                      <ng-option *ngFor="let each of hazardTypeList" [value]="each.name" [disabled]="each.isDisabled">
                        {{each.name | translate}}</ng-option>
                    </ng-select>
                  </div>
                </div>

                <div class="inp-container">
                  <ion-label style="font-weight: bold;">{{"Select Hazard Source"| translate}}</ion-label>
                  <div>
                    <ng-select
                      [readonly]="(this.roadmapStatus ==='Completed' && this.isCustom === 'false') || (this.isRiskReassessment && !this.addNewHazard)"
                      dropdownPosition="bottom" [searchable]="false" class="cust-name-select" [items]="translatedHazardSourceList"
                      [multiple]="true" [selectableGroup]="true" [closeOnSelect]="false" bindLabel="source"
                      [(ngModel)]="newHazard.source" #source="ngModel" name="source"
                      placeholder="{{'Select'|translate}}">
                    </ng-select>
                  </div>
                </div>

                <div class="inp-container">
                  <ion-label style="font-weight: bold;">{{'Select Hazard Consequences'|translate}}</ion-label>
                  <div>

                    <ng-select
                      [readonly]="(this.roadmapStatus ==='Completed' && this.isCustom === 'false') || (this.isRiskReassessment && !this.addNewHazard)"
                      dropdownPosition="bottom" [searchable]="false" class="cust-name-select"
                      [items]="translatedHazardConsequencesList" [multiple]="true" [selectableGroup]="true"
                      [closeOnSelect]="false" bindLabel="consequence" [(ngModel)]="newHazard.consequences"
                      #consequences="ngModel" name="consequences" placeholder="{{'Select'|translate}}">
                    </ng-select>
                  </div>
                </div>
              </ion-card>
            </ion-card-content>
          </ion-card>
          <!--HAZARD TYPE CARD END-->

          <!--HAZARD RATING CARD START-->
          <ion-card class="main-card">
            <ion-card-header class="ion-header-css">{{"Current Hazard Rating Number (HRN Calculation)"| translate}}
            </ion-card-header>
            <div class="inp-container" style="padding: 0px 25px;">
              <ion-label style="font-weight: bold;">{{'Select Machine Mode'|translate}}</ion-label>
              <ion-row>
                <ion-col>
                  <div>
                    <ng-select
                      [readonly]="(this.roadmapStatus ==='Completed' && this.isCustom === 'false')|| (this.isRiskReassessment && !this.addNewHazard)"
                      dropdownPosition="bottom" [loading]="getMachineModesLoading" bindLabel="machineMode"
                      class="cust-name-select" [items]="translatedMachineModeList" [multiple]="true" [selectableGroup]="true"
                      [closeOnSelect]="true" [(ngModel)]="hardDetailsData.machineMode" (change)="changeMode($event)"
                      #machineMode="ngModel" name="machineMode" placeholder="{{'Select Machine Mode'|translate}}">
                      <ng-template ng-option-tmp let-item="item" let-item$="item$.selected" let-index="index">
                        <input id="item-{{index}}" type="checkbox" [ngModelOptions]="{standalone: true}"
                          [ngModel]="item$" />
                        {{item.machineMode | translate}}
                      </ng-template>
                    </ng-select>
                  </div>
                </ion-col>
              </ion-row>
            </div>
            <ion-card-content>
              <ion-card class="inner-card scrollVertical">
                <div class="view" style="margin-top:-3em">
                  <div class="wrapper" style="height: 30.5rem !important;">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>{{'List'|translate}}</th>
                          <th *ngFor="let item of this.machineModeLists"><span style="color: #0281d2;">{{item}}</span>
                            <br> {{'Weightage'|translate}}
                          </th>
                        </tr>
                      </thead>
                      <tbody ngModelGroup="chrnTable">
                        <tr *ngFor="let each of hazardRatingList;let i =index" [ngModelGroup]="each.listName">
                          <td>{{each.listNameView | translate}}</td>
                          <td *ngFor="let item of machineModeLists;let i =index">
                            <ng-select
                              [readonly]="(this.roadmapStatus ==='Completed' && this.isCustom === 'false')|| (this.isRiskReassessment && !this.addNewHazard)"
                              [items]="each.weightagesList" [clearable]="false" placeholder="{{'Select'|translate}}"
                              class="dropdown" [(ngModel)]="each.dataChrn[item]"
                              (change)="updateIndicativeHRN(each,hazardRatingList,i)" #crntlMaintance="ngModel"
                              [name]="item">
                            </ng-select>
                          </td>
                        </tr>
                        <tr style="height: 40px;"></tr>
                        <tr style="border-top: 1px solid #d8dee0;">
                          <td style="color: #0e0c0c;">{{ 'Hazard Rating Number (HRN)' | translate}}</td>
                          <td *ngFor="let item of chrnTotal;let i =index">
                            <ion-input
                              [ngClass]="[item <=5 ? 'rating-green' : item <= 50 ? 'rating-yellow' :item <=500 ?'rating-orange':item <=1000 ?'rating-red':'rating-red']"
                              readonly [value]="item>=0 ? item : '' "></ion-input>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </ion-card>
            </ion-card-content>
            <div class="note-label">
              <small>{{"Hazard_Note" | translate}}- HRN(LO x FE x DPH x NPE)</small>
            </div>
          </ion-card>
          <!--HAZARD RATING CARD END-->

          <ion-row class="initial-hazard-margin">
            <ion-col size="6">
              <!--HAZARD INITIAL CARD START-->
              <ion-card class="hazard-card" style="height: 33em;">
                <ion-card-header class="ion-header-css">{{"Initial Hazard"|translate}}</ion-card-header>
                <ion-button
                  [disabled]="(this.roadmapStatus ==='Completed' && this.isCustom === 'true')|| (this.isRiskReassessment && !this.addNewHazard)"
                  fill="outline" size="small" class="pick-initial-btn" shape="round"
                  (click)="openPickHazardsList('Pick Initial Hazard')" position="bottom">
                  <ion-icon slot="start" style="font-size: 18px; font-family: Montserrat;" name="add-circle-outline">
                  </ion-icon>{{"Pick Initial Hazard" | translate}}
                </ion-button>
                <ion-card-content>
                  <ion-card class="inner-card scrollVertical" style="height: 7em;">
                    <ion-textarea
                      [readonly]="(this.roadmapStatus ==='Completed' && this.isCustom === 'false')|| (this.isRiskReassessment && !this.addNewHazard)"
                      [(ngModel)]="hardDetailsData.pickInitialHazard" #pickInitialHazard="ngModel"
                      name="pickInitialHazard" style="margin-top:-3em;color: #0e0c0c;">
                    </ion-textarea>
                  </ion-card>
                </ion-card-content>
                <div class="bt-right-container">
                  <ion-button class="app-large-btn" shape="round" fill="outline" color="primary"
                    [disabled]="pickInitialHazardValue==hardDetailsData.pickInitialHazard || !hardDetailsData.pickInitialHazard || (this.roadmapStatus ==='Completed' && this.isCustom === 'true') || (this.isRiskReassessment && !this.addNewHazard)"
                    (click)="initialMeasureAddToLibrary(hardDetailsData.pickInitialHazard)">
                    {{'Add to Library'|translate}}
                  </ion-button>
                </div>
              </ion-card>
              <!--HAZARD RATING CARD END-->
            </ion-col>

            <ion-col size="6">
              <!--HAZARD CONTROL MEASURES CARD START-->
              <ion-card class="hazard-card" style="height: 33em;">
                <ion-card-header class="ion-header-css">{{'Control Measure'|translate}}</ion-card-header>
                <ion-button
                  [disabled]="(this.roadmapStatus ==='Completed' && this.isCustom === 'true') || (this.isRiskReassessment && !this.addNewHazard)"
                  fill="outline" size="small" class="pick-initial-btn" shape="round" position="bottom"
                  (click)="openPickHazardsList('Pick Control Measure')">
                  <ion-icon slot="start" style="font-size: 18px;  font-family: Montserrat;" name="add-circle-outline">
                  </ion-icon>{{"Pick Control Measure"|translate}}
                </ion-button>
                <ion-card-content>
                  <ion-card class="inner-card scrollVertical" style="height: 7em;">
                    <ion-textarea
                      [readonly]="(this.roadmapStatus ==='Completed' && this.isCustom === 'false')|| (this.isRiskReassessment && !this.addNewHazard)"
                      [(ngModel)]="hardDetailsData.pickControlMeasure"
                      #pickControlMeasure="ngModel" name="pickControlMeasure" style="margin-top:-3em;color: #0e0c0c;">
                    </ion-textarea>
                  </ion-card>
                </ion-card-content>
                <div class="bt-right-container spacing">
                  <ion-button class="app-large-btn" shape="round" fill="outline" color="primary"
                    [disabled]="(this.roadmapStatus ==='Completed' && this.isCustom === 'true') || (this.isRiskReassessment && !this.addNewHazard)"
                    (click)="determinePlr()">
                    {{'Determined PLR'|translate}}
                  </ion-button>
                  <ion-button class="app-large-btn" shape="round" fill="outline" color="primary"
                    [disabled]="pickCntrlMeasureValue==hardDetailsData.pickControlMeasure || !hardDetailsData.pickControlMeasure ||(this.roadmapStatus ==='Completed' && this.isCustom === 'true') || (this.isRiskReassessment && !this.addNewHazard)"
                    (click)="controlMeasureAddToLibrary(hardDetailsData.pickControlMeasure)">
                    {{'Add to Library'|translate}}
                  </ion-button>
                </div>

                <ion-row class="plr-class">
                  <ion-col size="4">
                    <ion-item lines="none">
                      <ion-label>
                        {{ 'Determined PLR' | translate}} <ion-icon name="information-circle-outline"></ion-icon>
                      </ion-label>
                    </ion-item>
                  </ion-col>
                  <ion-col size="4">
                    <ion-item lines="none">
                      <ion-label> {{'Recommended Category'|translate}} <small>(Optional)</small>
                        <ion-icon name="information-circle-outline"></ion-icon>
                      </ion-label>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row class="plr-input">
                  <ion-col size="4">
                    <ion-input readonly name="determinePlrValue" [(ngModel)]="hardDetailsData.determinePlrValue"
                      #determinePlrValue="ngModel"></ion-input>
                  </ion-col>
                  <ion-col size="4">
                    <ion-input readonly name="determineCategoryValue" [(ngModel)]="recommendedCategoryValue">
                    </ion-input>
                  </ion-col>
                  <ion-col size-xs='12' size-sm='6' size-md="6" size-lg="6" size-xl="6">
                    <ion-item lines="none" style="font-size: small;--background: none;">
                      <ion-checkbox
                        [disabled]="(this.roadmapStatus==='Completed' && this.isCustom==='true')|| (this.isRiskReassessment && !this.addNewHazard)"
                        [(ngModel)]="isIncludeCategoryChecked" mode="md" [ngModelOptions]="{standalone: true}"
                        (ionChange)="addCategoryValue()"></ion-checkbox>
                      <ion-label class="plr-label extra-include-cat">{{"Include Recommended Category"|translate}}
                      </ion-label>
                    </ion-item>
                  </ion-col>
                </ion-row>
              </ion-card>
              <!--HAZARD CONTROL MEASURES CARD END-->
            </ion-col>
          </ion-row>

          <!--HAZARD INDICATIVE CARD START-->
          <ion-card class="main-card">
            <ion-card-header class="ion-header-css">{{"Indicative_HRN" | translate}}</ion-card-header>
            <div class="inp-container" style="padding: 0px 25px;">
              <ion-label style="font-weight: bold;">{{'Machine Mode'|translate}}</ion-label>
              <div>
                <ion-item lines="none" style="font-size: 12px;">
                  <span *ngFor="let item of this.machineModeLists;let i=index">{{item}}{{i==machineModeLists.length-1 ?
                    '':','}}&nbsp;&nbsp;</span>
                </ion-item>
              </div>
            </div>
            <ion-card-content>
              <ion-card class="inner-card scrollVertical">
                <div class="view" style="margin-top:-3em">
                  <div class="wrapper">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>{{'List'|translate}}</th>
                          <th *ngFor="let item of this.machineModeLists"><span style="color: #0281d2;">{{item}}</span>
                            <br> {{'Weightage'|translate}}
                          </th>
                        </tr>
                      </thead>
                      <tbody ngModelGroup="ihrnTable">
                        <tr *ngFor="let each of hazardRatingList" [ngModelGroup]="each.listName">
                          <td class="weight-list-value"> {{each.listNameView | translate}}
                          <td *ngFor="let item of machineModeLists;let i =index">
                            <ng-select
                              [readonly]="(this.roadmapStatus ==='Completed' && this.isCustom === 'false') || (this.isRiskReassessment && !this.addNewHazard)"
                              [items]="each.weightagesList" [clearable]="false" placeholder="{{'Select'|translate}}"
                              class="dropdown" [(ngModel)]="each.dataIhrn[item]" #ihrnCalculation="ngModel"
                              [name]="item">
                            </ng-select>
                          </td>
                        </tr>
                        <tr>
                          <td class="weight-hrn-cal-label" style="z-index: 100;">Hazard Rating Number(HRN)
                          </td>
                          <td *ngFor="let item of ihrnTotal;let i =index" style="padding-right: 5px;padding-left: 5px;">
                            <ion-input
                              [ngClass]="[item <=5 ? 'rating-green' : item <= 50 ? 'rating-yellow' :item <=500 ?'rating-orange':item <=1000 ?'rating-red':'rating-red']"
                              readonly [value]="item>=0 ? item : '' "></ion-input>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </ion-card>
            </ion-card-content>
            <div class="note-label">
              <small>{{"Hazard_Note" | translate}}- HRN(LO x FE x DPH x NPE)</small>
            </div>
          </ion-card>
          <!--HAZARD INDICATIVE CARD END-->

          <!--HAZARD RISK REASSESSMENT CURRENT  RATING CARD START-->
          <ion-card class="main-card" [hidden]="!this.isRiskReassessment">
            <ion-card-header class="ion-header-css">
              {{"Risk Re-Assessment Current Hazard Rating Number (HRN Calculation)"| translate}}
            </ion-card-header>
            <div class="inp-container" style="padding: 0px 25px;">
              <ion-label style="font-weight: bold;">{{'Select Machine Mode'|translate}}</ion-label>
              <ion-row>
                <ion-col>
                  <div>
                    <ng-select dropdownPosition="bottom" [readonly]="(this.roadmapStatus ==='Completed' && this.isCustom === 'false')"
                      bindLabel="machineMode" class="cust-name-select" [items]="riskReMachineModeList" [multiple]="true"
                      [selectableGroup]="true" [closeOnSelect]="true"
                      [(ngModel)]="hardDetailsData.RiskResassessmentMachineMode"
                      (change)="changeRiskResassessmentMachineMode($event)" #RiskResassessmentMachineMode="ngModel"
                      name="RiskResassessmentMachineMode" placeholder="{{'Select Machine Mode'|translate}}">
                      <ng-template ng-option-tmp let-item="item" let-item$="item$.selected" let-index="index">
                        <input id="item-{{index}}" type="checkbox" [ngModelOptions]="{standalone: true}"
                          [ngModel]="item$" />
                        {{item.machineMode | translate}}
                      </ng-template>
                    </ng-select>
                  </div>
                </ion-col>
              </ion-row>
            </div>
            <ion-card-content>
              <ion-card class="inner-card scrollVertical">
                <div class="view" style="margin-top:-3em">
                  <div class="wrapper" style="height: 30.5rem !important;">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>{{'List'|translate}}</th>
                          <th *ngFor="let item of this.riskResassessmentmachineModeLists"><span
                              style="color: #0281d2;">{{item}}</span>
                            <br> {{'Weightage'|translate}}
                          </th>
                        </tr>
                      </thead>
                      <tbody ngModelGroup="riskchrnTable">
                        <tr *ngFor="let each of hazardRatingList;let i =index" [ngModelGroup]="each.listName">
                          <td>{{each.listNameView | translate}}</td>
                          <td *ngFor="let item of riskResassessmentmachineModeLists;let i =index">
                            <ng-select [items]="each.weightagesList" [readonly]="(this.roadmapStatus ==='Completed' && this.isCustom === 'false')"
                              [clearable]="false" placeholder="{{'Select'|translate}}" class="dropdown"
                              [(ngModel)]="each.dataRiskChrn[item]"
                              (change)="updateRiskIndicativeHRN(each,hazardRatingList,i)" #riskcrntlMaintance="ngModel"
                              [name]="item">
                            </ng-select>
                          </td>
                        </tr>
                        <tr style="height: 40px;"></tr>
                        <tr style="border-top: 1px solid #d8dee0;">
                          <td style="color: #0e0c0c;">Hazard Rating Number (HRN)</td>
                          <td *ngFor="let item of chrnRiskTotal;let i =index">
                            <ion-input
                              [ngClass]="[item <=5 ? 'rating-green' : item <= 50 ? 'rating-yellow' :item <=500 ?'rating-orange':item <=1000 ?'rating-red':'rating-red']"
                              readonly [value]="item>=0 ? item : '' "></ion-input>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </ion-card>
            </ion-card-content>
            <div class="note-label">
              <small>Note: Hazard Rating Number- HRN(PO x SD x FE x NP)</small>
            </div>
          </ion-card>
          <!--RISK REASSESSMENT CURRENT HAZARD RATING CARD END-->

          <!--HAZARD RISK REASSESSMENT INDICATIVE CARD START-->
          <ion-card class="main-card" [hidden]="!this.isRiskReassessment">
            <ion-card-header class="ion-header-css">Risk Re-Assessment Indicative Hazard Rating
              Number(HRN)</ion-card-header>
            <div class="inp-container" style="padding: 0px 25px;">
              <ion-label style="font-weight: bold;">{{'Machine Mode'|translate}}</ion-label>
              <div>
                <ion-item lines="none" style="font-size: 12px;">
                  <span
                    *ngFor="let item of this.riskResassessmentmachineModeLists;let i=index">{{item}}{{i==riskResassessmentmachineModeLists.length-1
                    ?
                    '':','}}&nbsp;&nbsp;</span>
                </ion-item>
              </div>
            </div>
            <ion-card-content>
              <ion-card class="inner-card scrollVertical">
                <div class="view" style="margin-top:-3em">
                  <div class="wrapper">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>{{'List'|translate}}</th>
                          <th *ngFor="let item of this.riskResassessmentmachineModeLists"><span
                              style="color: #0281d2;">{{item}}</span>
                            <br> {{'Weightage'|translate}}
                          </th>
                        </tr>
                      </thead>
                      <tbody ngModelGroup="riskihrnTable">
                        <tr *ngFor="let each of hazardRatingList" [ngModelGroup]="each.listName">
                          <td class="weight-list-value"> {{each.listNameView | translate}}
                          <td *ngFor="let item of riskResassessmentmachineModeLists;let i =index">
                            <ng-select [items]="each.weightagesList" [clearable]="false"
                              placeholder="{{'Select'|translate}}" class="dropdown"
                              [(ngModel)]="each.dataRiskIhrn[item]" #ihrnCalculation="ngModel" [name]="item">
                            </ng-select>
                          </td>
                        </tr>
                        <tr>
                          <td class="weight-hrn-cal-label" style="z-index: 100;">Hazard Rating Number(HRN)
                          </td>
                          <td *ngFor="let item of ihrnRiskTotal;let i =index"
                            style="padding-right: 5px;padding-left: 5px;">
                            <ion-input
                              [ngClass]="[item <=5 ? 'rating-green' : item <= 50 ? 'rating-yellow' :item <=500 ?'rating-orange':item <=1000 ?'rating-red':'rating-red']"
                              readonly [value]="item>=0 ? item : '' "></ion-input>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </ion-card>
            </ion-card-content>
            <div class="note-label">
              <small>Note: Hazard Rating Number- HRN(PO x SD x FE x NP)</small>
            </div>
          </ion-card>
          <!--HAZARD RISK REASSESSMENT INDICATIVE CARD END-->

          <!--HAZARD UPLOAD IMAGE CARD START-->
          <ion-card class="main-card">
            <ion-card-header>
              <div class="flex-container">
                <div class="left-column">
                  {{  'Upload Images' | translate}}
                </div>
                <div class="right-column">
                  <label>
                    <input class="input-hazard-image" id="myHazardFileInput" #hazardImage type="file" multiple
                      (change)="inputHazardImg($event.target['files'],hazardImage)">
                    <div class="upload-div">
                      <ion-icon slot="start" name="add-circle-outline" class="close-icon"></ion-icon>
                    </div>
                  </label>
                </div>
              </div>
            </ion-card-header>
            <ion-list class="list-scroll no-bounce scrollbar">
              <ion-row *ngIf="previewHazardFile.length > 0">
                <ion-col size="2" *ngFor="let image of previewHazardFile;let i=index;">
                  <ion-card [ngStyle]="{ 'background-image': 'url(' + image.fileReaderResult + ')' }" class="card-transition local-image-card">
                    <span class="delete-hazard-img">
                      <ion-icon name="close-circle-outline" (click)="deleteImage(image,i)" tooltip="{{'Remove' | translate }}" placement="bottom"></ion-icon>
                    </span>
                  </ion-card>
                </ion-col>
              </ion-row>
            </ion-list>
          </ion-card>
          <ion-card class="main-card" *ngIf="hazardImagesRes.length > 0">
            <ion-card-header>{{ 'Images' | translate}}
            </ion-card-header>
            <ion-list class="list-scroll no-bounce scrollbar">
              <ion-row>
                <ion-col size="2" *ngFor="let item of hazardImagesRes">
                  <app-image-gallery [item]="item" [showRadioButton]="false"></app-image-gallery>
                </ion-col>
              </ion-row>
            </ion-list>
          </ion-card>
          <!--HAZARD UPLOAD IMAGE CARD END-->
        </ion-grid>
      </form>
    </div>
  </div>