import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { BackendCallService } from 'src/app/services/backend-call/backend-call.service';
import { NgForm } from '@angular/forms';
import { forkJoin, Observable, of } from 'rxjs';
import { LoadingController, ModalController } from '@ionic/angular';
import { PickHazardComponent } from '../../../../components/pick-hazard/pick-hazard.component';
import { Location } from '@angular/common';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ShareValuesService } from '../../../../services/sharedValues/share-values.service';
import { PlrScreenComponent } from '../../../../components/plr-screen/plr-screen.component';
import { hazardConsequences, hazardSources, hazardTypes } from 'src/app/modals/hazard-data';
import { isNotEmptyArray } from 'src/app/utilities/utils';
import { GraphService } from 'src/app/services/graph/graph.service';
import { UserAuthService } from 'src/app/services/user-auth/user-auth.service';
import { LanguageSupportService } from 'src/app/services/language-support.service';
import { AppConstants, initialHazards, StorageKeys } from 'src/app/utilities/constant';
import { ToastService } from 'src/app/services/toast.service';
import { ChrnTable } from '../../service-roadmaps/roadmap-interface';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
@Component({
  selector: 'app-add-hazard-details',
  templateUrl: './add-hazard-details.page.html',
  styleUrls: ['./add-hazard-details.page.scss'],
})
export class AddHazardDetailsPage implements OnInit,OnChanges {
  @Input() showHazardDetails : boolean = false;
  @Input() sections : any;
  @Output() exitHazardDetails = new EventEmitter<any[]>();
  @ViewChild('addHazardDetails') form: NgForm;
  hardDetailsData: any = {};
  hazardTypeList = hazardTypes;
  hazardSourceList = [];
  translatedHazardSourceList: any[] = [];
  hazardConsequencesList = [];
  translatedHazardConsequencesList: any[] = [];
  translatedMachineModeList: any[] = [];
  reviewImageSrc: string | ArrayBuffer;
  previewHazardFile: any[]=[];
  imageFiles: File[];
  addNewHazardDetails: any[] = [{}];
  gethazardTypeListLoading: boolean;
  gethazardSourceListLoading: boolean;
  gethazardConsequencesListLoading: boolean;
  isIncludeCategoryChecked = false;
  recommendedCategoryValue: any;
  hazardImagesList = [];
  hazardImagesRes: any[]=[];
  selectedLanguage: string = this.serviceMulti.getDefaultLanguage();
  machineModeList = [{ machineMode: 'Normal' }, { machineMode: 'Setup' }, { machineMode: 'Maintenance' }, { machineMode: 'Other' }];
  riskReMachineModeList = [{ machineMode: 'Normal' }, { machineMode: 'Setup' }, { machineMode: 'Maintenance' }, { machineMode: 'Other' }];

  hazardRatingList = [
    { listName: 'Probability of Occurence', listNameView: this.selectedLanguage == 'en' ? 'Likelihood Of Occurences (LO)' : 'Eintrittswahrscheinlichkeit (LO)', weightagesList: this.selectedLanguage == 'en' ? this.sharedValue.weightagesList : this.sharedValue.weightagesListGermanLng, dataChrn: {},dataRiskChrn: {},dataRiskIhrn: {}, dataIhrn: {}, rating: '' },
    { listName: 'Frequency of Exposition', listNameView: this.selectedLanguage == 'en' ? 'Frequency Of Exposure (FE)' : 'Häufigkeit der Gefährdung (FE)', dataChrn: {},dataRiskChrn: {},dataRiskIhrn: {}, weightagesList: this.sharedValue.FEweightagesList, dataIhrn: {}, rating: '' },
    { listName: 'Degree of Severity', listNameView: this.selectedLanguage == 'en' ? 'Degree Of Possible Harm (DPH)' : 'Schweregrad der Verletzung (DPH)', dataChrn: {},dataRiskChrn: {},dataRiskIhrn: {}, weightagesList: this.sharedValue.DPEweightagesList, dataIhrn: {}, rating: '' },
    { listName: 'Number of Persons Exposed', listNameView: this.selectedLanguage == 'en' ? 'Number of Persons at Risk (NPE)' : 'Anzahl der gefährdeten Personen (NPE)', dataChrn: {},dataRiskChrn: {},dataRiskIhrn: {}, weightagesList: this.sharedValue.NPEweightagesList, dataIhrn: {}, rating: '' }];
  machineModeLists = [];//[{ machineMode: "Setup", id: "2C7BF27C-F6BF-42F3-B064-DB0F6B561699" },{ machineMode: "Normal", id: "2CDGBF27C-F6BF-42F3-B064-DB0F6B561699" }]
  getMachineModesLoading: boolean;
  sectionData: any;
  pickCntrlMeasureValue: any;
  pickInitialHazardValue: any;
  imageSrc: string | ArrayBuffer;
  hazardData: any;
  roadmapStatus='In-progress';
  isRiskReassessment=false;
  public isCustom='false';
  isEditingFourEyeQuality = false;
  machineModeWithIdsLists: any;
  riskMachineModesWithIds: any;
  addNewHazard: boolean;
  riskResassessmentmachineModeLists: any[];
  hazardSourceConnsequencesData: any[];
  serviceMachineId: string;
  projectId: any;
  machineId: any;
  roadmapId: any;
  serviceId: any;
  selectedMachineName: string;
  constructor(private serviceMulti: LanguageSupportService, public userAuthService: UserAuthService, public loadingController: LoadingController, public backendService: BackendCallService,
    public modalController: ModalController, private graphService: GraphService, 
    private toastService: ToastService,
    private translate: TranslateService,
    public location: Location, private route: ActivatedRoute, private router: Router, private sharedValue: ShareValuesService) {
      this.route.queryParams.subscribe(async (params) => {
        console.log("addhazard params", params)
        this.selectedMachineName=params.selectedMachineName;
        this.projectId = params?.project_Id;
        this.machineId = params?.machineId;
        this.roadmapId = params?.roadmapId;
        this.serviceId = params?.serviceId;
      });
      
      
        
  }  
  ngOnChanges(changes: SimpleChanges): void {   
        console.log(this.sectionData);
        this.getTranslatedMode(this.machineModeList).subscribe(translatedData => {
          this.translatedMachineModeList = translatedData;
        });
        const roadmapInfo = sessionStorage.getItem('hazardInfo');
        if(sessionStorage.getItem('hazardInfo'))
        {
        const roadmapDetails = JSON.parse(roadmapInfo);
        this.serviceMachineId =roadmapDetails.serviceMachineId;
        this.isEditingFourEyeQuality = roadmapDetails.isEditingFourEyeQuality;
        this.sectionData = roadmapDetails.sectionData;
        this.hardDetailsData = roadmapDetails.hazardData; 
        this.addNewHazard=roadmapDetails.addNewHazard;        
        if(this.isEditingFourEyeQuality && !this.addNewHazard){         
          this.roadmapStatus = roadmapDetails.roadmapStatus;
          this.isCustom=roadmapDetails.isCustom;
          this.isRiskReassessment=roadmapDetails.isRiskReassessment;
        }else if(!this.isEditingFourEyeQuality && this.addNewHazard){         
          this.roadmapStatus = roadmapDetails.roadmapStatus;
          this.isRiskReassessment=roadmapDetails.isRiskReassessment;
          this.isCustom=roadmapDetails.isCustom;
        }
        if (this.isEditingFourEyeQuality) {          
          if (isNotEmptyArray(this.hardDetailsData.hazardType)) {
            console.log('In If');
            this.addNewHazardDetails = [];
            this.hardDetailsData.hazardType.forEach(_type => {
              const hazardType = _type.hazardTypeName.split(' ');
              this.hazardSourceList = hazardSources[hazardType[0]];
              this.getTranslatedSource(_type.hazardSources).subscribe(translatedData => {
                _type.hazardSources = translatedData;
              });
              // To display default source list along with populated data
              this.getTranslatedSource(this.hazardSourceList).subscribe(translatedData => {
                this.translatedHazardSourceList = translatedData;
              });
              this.hazardConsequencesList = hazardConsequences[hazardType[0]];
              _type.hazardConsequences.map(item => item.consequence = item.consequences)
              this.getTranslatedConsequence(_type.hazardConsequences).subscribe(translatedData => {
                _type.hazardConsequences = translatedData;
              });
              // To display default consequence list along with populated data
              this.getTranslatedConsequence(this.hazardConsequencesList).subscribe(translatedData => {
                this.translatedHazardConsequencesList = translatedData;
              });
              const body = {
                hazardTypeId: '',
                hazardType: _type.hazardTypeName,
                source: _type.hazardSources,
                consequences: _type.hazardConsequences
              };
              if (_type.hazardTypeId) {body.hazardTypeId = _type.hazardTypeId;}
              this.addNewHazardDetails.push(body);
              this.addNewHazardDetails.forEach(_ad => {
                if (isNotEmptyArray(_ad.consequences)) {
                  _ad.consequences.forEach(_cs => {
                    _cs.consequence = _cs.consequences;
                  });
                }
              });
            });
            this.addNewHazardDetails.forEach(_ad => { _ad.consequences.forEach(_cs => { if (_cs.consequences) {delete _cs.consequences;} }); });
            this.hazardSourceConnsequencesData=JSON.parse(JSON.stringify(this.addNewHazardDetails));
            this.machineModeLists = this.hardDetailsData.machineModes;
            this.riskResassessmentmachineModeLists=this.hardDetailsData.riskmachineModes;
            this.hardDetailsData.machineMode = this.hardDetailsData.machineModes;
            this.getTranslatedMachineMode(this.hardDetailsData.machineModes).subscribe(translatedMode => {
              this.hardDetailsData.machineMode = translatedMode
            });
            this.machineModeWithIdsLists = this.hardDetailsData.machineModesWithIds;
            this.hardDetailsData.RiskResassessmentMachineMode=this.hardDetailsData?.riskmachineModes,
            this.riskMachineModesWithIds= this.hardDetailsData?.riskMachineModesWithIds,
            this.hazardRatingList = this.hardDetailsData.hazardRatingList;
            this.getTranslatedDatachrnLabels(this.hazardRatingList).subscribe(translatedDataList => {
              this.hazardRatingList = translatedDataList
            });
            this.hazardRatingList.map((element) => {
              this.getTranslatedWeightages(element?.['weightagesList']).subscribe(translatedDataList => {
                element.weightagesList = translatedDataList
              })
            })
            this.hazardImagesRes = this.hardDetailsData.hazardImagesList;//;?this.hardDetailsData.hazardImagesList:[];
            this.hardDetailsData.determinePlrValue = this.hardDetailsData.plrObj.determinedPLR;
            if (this.hardDetailsData.recommendedCategory && this.hardDetailsData.hazardName) {
              this.isIncludeCategoryChecked = true;
              this.recommendedCategoryValue = this.hardDetailsData.recommendedCategory;
            }
          }
        } else {  
          if (this.hardDetailsData) {
            console.log('In else');
            this.addNewHazardDetails = [{}];
            this.machineModeLists = [];
            this.hazardRatingList = [
              { listName: 'Probability of Occurence',
                listNameView: this.selectedLanguage == 'en' ? 'Likelihood Of Occurences (LO)' : 'Eintrittswahrscheinlichkeit (LO)', 
                weightagesList: this.selectedLanguage == 'en' ? this.sharedValue.weightagesList : this.sharedValue.weightagesListGermanLng,
                dataChrn: {},dataRiskChrn: {},dataRiskIhrn: {}, dataIhrn: {}, rating: '' 
              },
              { listName: 'Frequency of Exposition', listNameView: this.selectedLanguage == 'en' ? 'Frequency Of Exposure (FE)' : 'Häufigkeit der Gefährdung (FE)', dataChrn: {},dataRiskChrn: {},dataRiskIhrn: {}, weightagesList: this.selectedLanguage == 'en' ? this.sharedValue.FEweightagesList : this.sharedValue.FEweightagesGermanLng, dataIhrn: {}, rating: '' },
              { listName: 'Degree of Severity', listNameView: this.selectedLanguage == 'en' ? 'Degree Of Possible Harm (DPH)' : 'Schweregrad der Verletzung (DPH)', dataChrn: {},dataRiskChrn: {},dataRiskIhrn: {}, weightagesList: this.selectedLanguage == 'en' ? this.sharedValue.DPEweightagesList : this.sharedValue.DPEweightagesListGermanLng, dataIhrn: {}, rating: '' },
              { listName: 'Number of Persons Exposed', listNameView: this.selectedLanguage == 'en' ? 'Number of Persons at Risk (NPE)' : 'Anzahl der gefährdeten Personen (NPE)', dataChrn: {},dataRiskChrn: {},dataRiskIhrn: {}, weightagesList: this.sharedValue.NPEweightagesList, dataIhrn: {}, rating: '' }];
            const { hazardName, pickInitialHazard, pickControlMeasure, determinePlrValue } = this.hardDetailsData;
            if (hazardName) {this.hardDetailsData.hazardName = '';}
            if (pickInitialHazard) {this.hardDetailsData.pickInitialHazard = '';}
            if (pickControlMeasure) {this.hardDetailsData.pickControlMeasure = '';}
            if (this.hardDetailsData.category && this.hardDetailsData.hazardName) {
              this.isIncludeCategoryChecked = true;
              this.recommendedCategoryValue = this.hardDetailsData.category;
              this.hardDetailsData.determinePlrValue = this.hardDetailsData.plrObj.determinedPLR;
              this.hazardImagesRes = this.hardDetailsData.hazardImagesList;//;?this.hardDetailsData.hazardImagesList:[];              
            }
          }
        }
        this.hazardImagesRes.forEach(element => {
          element.graphUrl = element.url;          
        });  
      }    
    
  }
  /**
   * Handles form action
   */
  public handleFormAction() {
    if (this.form) {
      if (this.form.valid) {
        // Handle form submission
        this.complete(this.form);
      } else {
        this.form.control.markAllAsTouched();
      }
    }
  }

 /**
  * Gets machine image from graph
  *
  * @param machineData machineData
  */
  private getMachineImageFromGraph(machineData) {
    this.graphService.getMachineThumbnail(machineData.driveId, machineData.itemId)
    .toPromise().then((thumbnail: any) => {
      thumbnail.value.forEach((imageUrl, index) => {
        this.hazardImagesRes.map((image: any) => {
          image.graphUrl = imageUrl.large.url;
        });
      });
    });
  }

  addCategoryValue() {
    if (this.isIncludeCategoryChecked && this.recommendedCategoryValue) {this.hardDetailsData.recommendedCategory = this.recommendedCategoryValue;}
    else {this.recommendedCategoryValue = '';}
  }

  changeMode(ev: []) {
    this.machineModeLists = [];
    // this.sharedValue.machineModeLists = [];
    ev.forEach((ele: any) => {
      this.machineModeLists.push(ele.machineMode);
      // this.sharedValue.machineModeLists.push(ele.machineMode);
    });
  }

  changeRiskResassessmentMachineMode(ev: []) {
    this.riskResassessmentmachineModeLists = [];
    ev.forEach((ele: any) => {
      this.riskResassessmentmachineModeLists.push(ele.machineMode);
    });
  }

  getMachineLimits() {
    this.machineModeLists = [];
    // this.sharedValue.machineModeLists = [];
    if (this.serviceMachineId) {
      this.backendService.getMachineLimits(this.serviceMachineId)
        .subscribe(
          (data: any) => {
            if (data && isNotEmptyArray(data.machineMode)) {
              this.hardDetailsData.machineMode = data.machineMode;
              data.machineMode.forEach(_mm => {
                this.machineModeLists.push(_mm.machineMode);
                // this.sharedValue.machineModeLists.push(_mm.machineMode)
              });
            }
          },
          (err) => {
            console.log('getting issue in fetching machine limits data :', err);
          }
        );
    }
  }

  ngOnInit() {
    // listName: "Probability of Occurence", listNameView: "Probability Of Occurences(PO)",weightagesList:[] , dataChrn: {}, dataIhrn: {}, rating: '' },
    // { listName: "Degree of Severity", listNameView: "Degree Of Severity(SD)", dataChrn: {},weightagesList:[], dataIhrn: {}, rating: '' },
    // { listName: "Frequency of Exposition", listNameView: "Frequency Of Exposure(FE)", dataChrn: {},weightagesList:[], dataIhrn: {}, rating: '' },
    // { listName: "Number of Persons Exposed",
  }

  ionViewWillEnter() {
        this.hazardRatingList.forEach((element) => {
          if (this.selectedLanguage == 'en') {
            if (element.listName === 'Probability of Occurence') {
              element.weightagesList = this.sharedValue.weightagesList;
            } else if (element.listName === 'Frequency of Exposition') {
              element.weightagesList = this.sharedValue.FEweightagesList;
            } else if (element.listName === 'Number of Persons Exposed') {
              element.weightagesList = this.sharedValue.NPEweightagesList;
            } else if (element.listName === 'Degree of Severity') {
              element.weightagesList = this.sharedValue.DPEweightagesList;
            }
          } else if (this.selectedLanguage == 'de') {
            if (element.listName === 'Probability of Occurence') {
              element.weightagesList = this.sharedValue.weightagesListGermanLng;
            } else if (element.listName === 'Frequency of Exposition') {
              element.weightagesList = this.sharedValue.FEweightagesGermanLng;
            } else if (element.listName === 'Number of Persons Exposed') {
              element.weightagesList = this.sharedValue.NPEweightagesList;
            } else if (element.listName === 'Degree of Severity') {
              element.weightagesList = this.sharedValue.DPEweightagesListGermanLng;
            }
          }
        });
        if (!this.isEditingFourEyeQuality) {this.getMachineLimits();}
        this.hazardTypeList.forEach((each: any) => {
          each.isDisabled = false;
        });
        
  }

  updateIndicativeHRN(each, hazardRatingList, i) {
    each.dataIhrn = JSON.parse(JSON.stringify(each.dataChrn));
  }

  updateRiskIndicativeHRN(each, hazardRatingList, i) {
    each.dataRiskIhrn = JSON.parse(JSON.stringify(each.dataRiskChrn));
  }

  get chrnTotal() {
    return this.machineModeLists?.map((each) => {
      let temp = 1;
      let allDefiend = false;
      for (const eachVal of this.hazardRatingList) {
        if(!allDefiend){
          allDefiend = eachVal.dataChrn[each]?.value ? true : false;
        }
        temp = temp * parseFloat(eachVal.dataChrn[each]?.value || 1);
      }
      if (this.isEditingFourEyeQuality) {
        return temp.toFixed(4);
      } else {
        if(!allDefiend){
          return 0;
        }
        return temp == 0 ? 0 : temp.toFixed(4);
      }
    });

  }

  get chrnRiskTotal() {
    return this.riskResassessmentmachineModeLists?.map((each) => {
      let temp = 1;
      let allDefiend = false;
      for (const eachVal of this.hazardRatingList) {
        if(!allDefiend){
          allDefiend = eachVal?.dataRiskChrn[each]?.value ? true : false;
        }
        temp = temp * parseFloat(eachVal?.dataRiskChrn[each]?.value || 1);
      }
      if (this.isEditingFourEyeQuality) {
        return temp.toFixed(4);
      } else {
        if(!allDefiend){
          return 0;
        }
        return temp == 0 ? 0 : temp.toFixed(4);
      }
    });

  }

  async determinePlr() {
    const props = { isCategoryIncluded: this.isIncludeCategoryChecked };
    props['plrObj'] = this.hardDetailsData.plrObj;
    props['category'] = this.recommendedCategoryValue;
    const modal = await this.modalController.create({
      component: PlrScreenComponent,
      cssClass: 'plr-screen-class',
      backdropDismiss: false,
      componentProps: props,
      showBackdrop: false,
    });
    await modal.present();
    await modal.onDidDismiss().then((result) => {
      if (result?.data) {
        console.log('PLR data',result?.data);
        this.hardDetailsData.determinePlrValue = result.data.plr_value;
        if (result.data.category_value) {
          this.recommendedCategoryValue = result.data.category_value;
          if (this.isIncludeCategoryChecked) {this.hardDetailsData.category = result.data.category_value;}
        }
      }
    });
  }
  async selectEvent(ev: any, index: number) {
    const value = ev.split(' ')[0];
    this.hazardSourceList = hazardSources[value];
    this.getTranslatedSource(this.hazardSourceList).subscribe(translatedData => {
      this.translatedHazardSourceList = translatedData;
    });
    this.hazardConsequencesList = hazardConsequences[value];
    this.getTranslatedConsequence(this.hazardConsequencesList).subscribe(translatedData => {
      this.translatedHazardConsequencesList = translatedData;
    });
    this.addNewHazardDetails[index].consequences = null;
    this.addNewHazardDetails[index].source = null;
  }

  /**
   * To get the translated label of sources
   * @param sources 
   * @returns 
   */
  private getTranslatedSource(sources): Observable<any[]> {
    const sourceObservables = sources?.map(item =>
      this.translate.get(item.source)
    );
    return forkJoin(sourceObservables).pipe(
      map(translations => {
        return sources?.map((item, index) => { return {...item, source: translations[index]};});
      })
    );
  }

  /**
   * To get the translated label of consequences
   * @param consequences 
   * @returns 
   */
  private getTranslatedConsequence(consequences): Observable<any[]> {
    const consequenceObservables = consequences?.map(item =>
      this.translate.get(item.consequence)
    );
    return forkJoin(consequenceObservables).pipe(
      map(translations => {
        return consequences?.map((item, index) => { return {...item, consequence: translations[index], consequences: translations[index] };});
      })
    );
  }

  /**
   * To get the translated label of selected machine modes
   * @param modes 
   * @returns 
   */
  private getTranslatedMachineMode(modes: string[]): Observable<string[]> {
    const labelObservables = modes.map(mode => this.translate.get(mode));
    return forkJoin(labelObservables);
  }

  /**
   * To get the translated label of all machine modes
   * @param sources 
   * @returns 
   */
  private getTranslatedMode(modes): Observable<any[]> {
    const modeObservables = modes?.map(item =>
      this.translate.get(item.machineMode)
    );
    return forkJoin(modeObservables).pipe(
      map(translations => {
        return modes?.map((item, index) => { return {...item, machineMode: translations[index]};});
      })
    );
  }

  /**
   * To get the translated label of selected CHRN values
   * @param dataList
   * @returns 
   */
  private getTranslatedDatachrnLabels(dataList): Observable<any[]> {
    const observables = dataList.map(item => {
      const normalLabel = item?.dataChrn?.Normal?.label || '';
      const setupLabel = item?.dataChrn?.Setup?.label || '';
      const maintenanceLabel = item?.dataChrn?.Maintenance?.label || '';
      const otherLabel = item?.dataChrn?.Other?.label || '';
  
      const normalTranslation = normalLabel ? this.translate.get(normalLabel) : of(normalLabel);
      const setupTranslation = setupLabel ? this.translate.get(setupLabel) : of(setupLabel);
      const maintenanceTranslation = maintenanceLabel ? this.translate.get(maintenanceLabel) : of(maintenanceLabel);
      const otherTranslation = otherLabel ? this.translate.get(otherLabel) : of(otherLabel);
  
      return forkJoin([normalTranslation, setupTranslation, maintenanceTranslation, otherTranslation]).pipe(
        map(([translatedNormal, translatedSetup, translatedMaintenance, translatedOther]) => {
          return {
            ...item,
            dataChrn: {
              Normal: { label: translatedNormal },
              Setup: { label: translatedSetup },
              Maintenance: { label: translatedMaintenance },
              Other: { label: translatedOther }
            },
            dataIhrn: {
              Normal: { label: translatedNormal },
              Setup: { label: translatedSetup },
              Maintenance: { label: translatedMaintenance },
              Other: { label: translatedOther }
            }
          };
        })
      );
    });
  
    return forkJoin(observables);
  }

  /**
   * To get the translated label of selected weightage list
   * @param weightages 
   * @returns 
   */
  private getTranslatedWeightages(weightages): Observable<any[]> {
    const weightageObservables = weightages?.map(item => this.translate.get(item?.label));
      return forkJoin(weightageObservables).pipe(map(translations => {
        return weightages?.map((item, index) => { return {...item, label: translations[index]};});
      })
    );
  }

  get ihrnTotal() {
    return this.machineModeLists?.map((each) => {
      let temp = 1;
      let allDefiend = false;
      for (const eachVal of this.hazardRatingList) {
        if(!allDefiend){
          allDefiend = eachVal.dataIhrn[each]?.value ? true : false;
        }
        temp = temp * parseFloat(eachVal.dataIhrn[each]?.value || 1);
      }
      if (this.isEditingFourEyeQuality) {
        return temp.toFixed(4);
      } else {
        if(!allDefiend){
          return 0;
        }
        return temp == 0 ? 0 : temp.toFixed(4);
      }
    });
    // return this.machineModeLists?.map((each) => {
    //   let temp = 1;
    //   for (let eachVal of this.hazardRatingList) {
    //     temp = temp * parseFloat(eachVal.dataIhrn[each]?.value || 1);
    //   }
    //   // return temp == 1 ? 0 : temp;
    //   // return temp;
    //   return temp == 1 ? 0 : (Math.round(temp * 100) / 100).toFixed(4);
    // })
  }

  get ihrnRiskTotal() {
    return this.riskResassessmentmachineModeLists?.map((each) => {
      let temp = 1;
      let allDefiend = false;
      for (const eachVal of this.hazardRatingList) {
        if(!allDefiend){
          allDefiend = eachVal.dataRiskIhrn[each]?.value ? true : false;
        }
        temp = temp * parseFloat(eachVal.dataRiskIhrn[each]?.value || 1);
      }
      if (this.isEditingFourEyeQuality) {
        return temp.toFixed(4);
      } else {
        if(!allDefiend){
          return 0;
        }
        return temp == 0 ? 0 : temp.toFixed(4);
      }
    });
    // return this.machineModeLists?.map((each) => {
    //   let temp = 1;
    //   for (let eachVal of this.hazardRatingList) {
    //     temp = temp * parseFloat(eachVal.dataIhrn[each]?.value || 1);
    //   }
    //   // return temp == 1 ? 0 : temp;
    //   // return temp;
    //   return temp == 1 ? 0 : (Math.round(temp * 100) / 100).toFixed(4);
    // })
  }

  controlMeasureAddToLibrary(pickInitialHazard) {
    const payload = [
      {
        id: '',
        value: pickInitialHazard,
        userId: this.userAuthService.getUserList().userId,//"7892B01E-5EE1-4702-81C1-DE9D35EA2FA9"
      }
    ];
    this.backendService.controlMeasureAddToLibrary(payload).subscribe((res: any) => {      
      this.toastService.presentToast(res.value, 4000, AppConstants.SUCCESS_SNACKBAR, 'top');
    },err=>{
      this.toastService.presentToast('No Results Found', 4000, AppConstants.ERROR_SNACKBAR, 'top');      
    });
  }

  initialMeasureAddToLibrary(pickInitialHazard) {
    const payload = [
      {
        id: '',
        value: pickInitialHazard,
        userId: this.userAuthService.getUserList().userId,//"7892B01E-5EE1-4702-81C1-DE9D35EA2FA9"
      }
    ];
    this.backendService.initialMeasureAddToLibrary(payload).subscribe((res: any) => {
      this.toastService.presentToast(res.value, 4000, AppConstants.SUCCESS_SNACKBAR, 'top');
    },err=>{
      this.toastService.presentToast('No Results Found', 4000, AppConstants.ERROR_SNACKBAR, 'top');      
    });
  }

  deleteImage(eachImg, i) {
    if (eachImg.id && this.roadmapStatus!=='Completed') {
      const payload = {
        id: eachImg.id,
        fileName: eachImg.fileName,
        contentType: '',
        url: eachImg.url,
        driveId: eachImg.driveId,
        itemId: eachImg.itemId,
        isType: 'hazard'
      };
      this.backendService.deleteMediaFile(payload).subscribe((res: any) => {
        this.hazardImagesList.forEach((element) => {
          if (element.fileName == eachImg.fileName) {this.hazardImagesList.splice(i, 1);}
        });
        this.graphService.deleteItem(eachImg.driveId, eachImg.itemId).subscribe(res => {
        });
      },err=>{
        this.toastService.presentToast('No Results Found', 4000, AppConstants.ERROR_SNACKBAR, 'top'); 
      });


    } else {
      this.previewHazardFile.splice(i, 1);
    }
  }

  async complete(form: NgForm) {
    //await this.uploadToSharePoint();
    await this.completedData(form);
  }

  completedData(form: NgForm) {
    if (form.valid) {
      if (this.isEditingFourEyeQuality) {
        this.saveFourEyeQualityEditInfo(form.value);
      }
      else {
        const navigationExtras: NavigationExtras = {
          queryParamsHandling: 'merge',
          // state: {
          //   hazardDetails: { hazardRatingList: this.hazardRatingList, formData: form.value, recommendedCategory: this.recommendedCategoryValue, files: this.hazardImagesList, pickCntrlMeasureValue: this.pickCntrlMeasureValue, pickInitialHazardValue: this.pickInitialHazardValue, determinePlrValue: this.hardDetailsData.determinePlrValue, isEdited: this.isEditingFourEyeQuality },
          //   sectionData: this.sectionData
          // },
        };
        const newhazardDetails = {
          hazardDetails: {
            hazardRatingList: this.hazardRatingList,
            formData: form.value,
            recommendedCategory: this.recommendedCategoryValue,
            files: this.hazardImagesList,
            pickCntrlMeasureValue: this.pickCntrlMeasureValue,
            pickInitialHazardValue: this.pickInitialHazardValue,
            determinePlrValue: this.hardDetailsData.determinePlrValue,
            isEdited: this.isEditingFourEyeQuality
          },
          sectionData: this.sectionData
        };

        

        this.sections.forEach(section => {
          if(section.sectionName == this.sectionData.sectionName)
          {
            section.subSections.forEach(subsection => {
              if(subsection.subSectionName ==this.sectionData.subSectionName)
              {
                    let finalHrNs = [];
                    let currentHrNs = {
                      hrnType: "Current",
                      version:"",
                      value:[]
                    }
                    form.value.machineMode.forEach(_element => {
                      console.log(_element.machineMode);                       

                      let values = {
                        mode : _element.machineMode,
                        po : form.value.chrnTable['Probability of Occurence'][_element.machineMode].id,
                        sd : form.value.chrnTable['Degree of Severity'][_element.machineMode].id,
                        fe : form.value.chrnTable['Frequency of Exposition'][_element.machineMode].id,
                        np : form.value.chrnTable['Number of Persons Exposed'][_element.machineMode].id,
                        rating : Math.round(form.value.chrnTable['Probability of Occurence'][_element.machineMode]*
                          form.value.chrnTable['Degree of Severity'][_element.machineMode].id*
                          form.value.chrnTable['Frequency of Exposition'][_element.machineMode].id*
                          form.value.chrnTable['Number of Persons Exposed'][_element.machineMode].id)
                      }
                      currentHrNs.value.push(values);
                    });
                    finalHrNs.push(currentHrNs); 
                    let indicativeHrNs = {
                      hrnType: "Indicative",
                      version:"",
                      value:[]
                    } 
                    form.value.machineMode.forEach(_element => {
                      console.log(_element.machineMode);                       

                      let values = {
                        mode : _element.machineMode,
                        po : form.value.ihrnTable['Probability of Occurence'][_element.machineMode].id,
                        sd : form.value.ihrnTable['Degree of Severity'][_element.machineMode].id,
                        fe : form.value.ihrnTable['Frequency of Exposition'][_element.machineMode].id,
                        np : form.value.ihrnTable['Number of Persons Exposed'][_element.machineMode].id,
                        rating : Math.round(form.value.ihrnTable['Probability of Occurence'][_element.machineMode]*
                          form.value.ihrnTable['Degree of Severity'][_element.machineMode].id*
                          form.value.ihrnTable['Frequency of Exposition'][_element.machineMode].id*
                          form.value.ihrnTable['Number of Persons Exposed'][_element.machineMode].id)
                      }
                      indicativeHrNs.value.push(values);
                    });
                    finalHrNs.push(indicativeHrNs);   
                    console.log(finalHrNs);   
                    
                    if(this.addNewHazard)
                    {
                      let hazard = {
                          hazards: [{
                             id:"",
                             hazardName : form.value.hazardName,
                             hazardTypes:[
                              {
                                type: form.value.hazardTypesData[0].hazardType,
                                sources: form.value.hazardTypesData[0].source,
                                consequences: form.value.hazardTypesData[0].consequences
                              }
                             ],
                             hazardRatingNumber: {
                              machineModes: form.value.machineMode,
                              hrNs: finalHrNs
                             },
                             initialHazard: this.pickInitialHazardValue,
                             countermeasure: this.pickCntrlMeasureValue,
                             determinedPLR:this.hardDetailsData.determinePlrValue,
                             category:this.recommendedCategoryValue,
                             files:this.hazardImagesList,
                             plrObj:{
                              id:"",
                              determinedPLR:this.hardDetailsData.determinePlrValue,
                              sValue : this.sharedValue.sValue,
                              fValue : this.sharedValue.fValue,
                              pValue : this.sharedValue.pValue
                             }
                             }]
                            };
                      subsection.steps[this.sectionData.index].hazardDetails=hazard;
                      subsection.steps[this.sectionData.index].answers[0].hazardDetails?.push({id :"", name : form.value.hazardName});
                    }               
                }
              });
            }
               
          });
        
        sessionStorage.setItem(StorageKeys.NEW_HAZARD_DETAILS, JSON.stringify(newhazardDetails));
        // this.location.back();
        console.log(this.sections);
        this.exitHazardDetails.emit(this.sections);
        //this.router.navigate([window.location.pathname], navigationExtras);
      }
    } else {
      form.form.markAllAsTouched();
      this.toastService.presentToast('Please fill all the mandatory fields', 4000, AppConstants.ERROR_SNACKBAR, 'top'); 
    }
  }

  saveFourEyeQualityEditInfo(hazardInfo) {
    if (hazardInfo && hazardInfo.hazardName) {
      const hazardPayload = this.sharedValue.initializeEditFourEyeHazardLists(this.recommendedCategoryValue, this.hardDetailsData.id, hazardInfo, this.machineModeLists,this.riskResassessmentmachineModeLists,this.riskMachineModesWithIds, this.machineModeWithIdsLists, this.hazardRatingList, this.addNewHazardDetails, this.hazardImagesList,this.hazardSourceConnsequencesData);
      if (hazardPayload && hazardPayload.id) {
        this.backendService.saveEditedHazardInfoInFourEyeQuality(hazardPayload).then(res => {
          if (res && res == 'Hazard Updated Successfully') {
            this.toastService.presentToast('Hazard edited successfully.', 4000, AppConstants.SUCCESS_SNACKBAR, 'top');
            // this.sharedValue.editHazardPayload.push({id:hazardPayload.id,name:hazardPayload.name});
            const state = {
              hazardDetails: { hazardRatingList: this.hazardRatingList, formData: hazardInfo, files: this.hazardImagesList, pickCntrlMeasureValue: this.pickCntrlMeasureValue, pickInitialHazardValue: this.pickInitialHazardValue, determinePlrValue: this.hardDetailsData.determinePlrValue, isEdited: this.isEditingFourEyeQuality, editedHazardId: this.hardDetailsData.id },
              sectionData: this.sectionData
            };
            this.sharedValue.editHazardPayload = state;
            this.goBack();
          }
        }).catch(err => {
          console.log('getting issue in saving hazard info for 4-eye quality :', err);
        });
      }
    }
  }


  async openPickHazardsList(type) {
    const props = { type };
    const modal = await this.modalController.create({
      component: PickHazardComponent,
      cssClass: 'pickhazard-alert-modal',
      backdropDismiss: false,
      componentProps: props,
      showBackdrop: false,
    });
    await modal.present();
    await modal.onDidDismiss().then((result) => {
      if (result && result.data) {
        if (type == 'Pick Control Measure') {
          this.pickCntrlMeasureValue = result.data.value;
          this.hardDetailsData.pickControlMeasure = result.data.value;
        } else {
          this.pickInitialHazardValue = result.data.value;
          this.hardDetailsData.pickInitialHazard = result.data.value;
        }
      }
    });
  }

  goBack() {
    this.exitHazardDetails.emit(this.sections);
  }

  /**
   * To input the image files and upload to server using formData
   * @param files 
   * @param hazardImage 
   */
  public inputHazardImg(files: File[], hazardImage: HTMLInputElement) {
    const self = this;
    this.imageFiles = Array.from(files);
    this.uploadHazardImages();
    for (const file of files) {
      const reader = new FileReader();
      const fileSize = file.size;
      const imgData = {
        fileName: file.name,
        fileSize: file.size,
        fileReaderResult: reader.result,
        fileData: file
      };
      reader.onload = e => {
        self.reviewImageSrc = reader.result;
        imgData.fileReaderResult = reader.result;
        if (fileSize < 4194304) {
          self.previewHazardFile.push(imgData);
        } else {
         self.previewHazardFile.push(imgData);
          // this.sharedValue.errorShowToast("","Image size exceeds 4MB")
        }
      };
      reader.readAsDataURL(file);
      reader.onerror = (error) => {
      };

    }
    hazardImage.value = '';
  }

  /**
   * To upload the selected images on the server
   */
  private async uploadHazardImages() {
    const file_path = `${this.projectId}/${this.serviceId}/${this.machineId}/${this.roadmapId}/`;
    const formData = new FormData();
    const hazardImageFormData = this.sharedValue.prepareImageFormData(formData, this.imageFiles, file_path)
    await this.backendService.uploadImages(hazardImageFormData).subscribe((res: any) => {
      console.log(res);
      res.forEach(element => {
      const imageData = {
        fileName: element?.file_name,
        contentType: element?.content_type,
        url: element?.url,
        image_Size: element?.file_size
      };
      this.hazardImagesList.push(imageData);
      })
    }, (err) => {
    });
  }

  async presentLoading(msg) {
    const loading = await this.loadingController.create({
      cssClass: 'my-custom-loading',
      message: msg,
      spinner: 'bubbles'
    });
    await loading.present();
    return loading;
  }


  async uploadToSharePoint() {
    if (this.previewHazardFile.length > 0) {
      for (const element of this.previewHazardFile) {
        const Mac_name = this.selectedMachineName.replace(/ /g, '') || this.selectedMachineName.replace(/ /g, '');
        const folderPath = 'General/Machines' + '/' + Mac_name;
        const UploadImageFolderPath = 'Pictures';
        await this.checkFolderExists(folderPath, UploadImageFolderPath);
        await this.UploadFileToSharePoint(folderPath + '/' + UploadImageFolderPath, element.fileName, element.fileData, element.fileSize);
      }
    }
  }

  async UploadFileToSharePoint(folderPath, fileName, file, fileSize) {
    if (fileSize < 4194304) {
      const res: any = await this.graphService.UploadFile(fileName, file, folderPath).toPromise();
      if (res) {
        const imageData = {
          fileName: res.name,
          contentType: res.file.mimeType,
          url: res.webUrl,
          driveId: res.parentReference.driveId,
          itemId: res.id,
          image_Size:fileSize
        };
        this.hazardImagesList.push(imageData);
      }
    } else if(fileSize < 94371840){
      const res: any =  await this.graphService.createUploadSession(fileName,fileSize,folderPath).toPromise();//.subscribe(async(res: any) => {
        if(res.uploadUrl){
          await this.sharedValue.uploadChunks(file, res.uploadUrl).then(async (res: any)=>{
            if (res[1]) {
              const imageData = {
                fileName: res[1].name,
                contentType: res[1].file.mimeType,
                url: res[1]['@microsoft.graph.downloadUrl'],//res.webUrl,
                driveId: res[1].parentReference.driveId,
                itemId: res[1].id,
                image_Size:fileSize
              };
              this.hazardImagesList.push(imageData);
            }
          });
        }else if (res.error) {          
          this.toastService.presentToast(res.error.message, 4000, AppConstants.ERROR_SNACKBAR, 'top');
        }
    }else{
      this.toastService.presentToast('Image size exceeds 100MB', 4000, AppConstants.ERROR_SNACKBAR, 'top');
    }
  }

  /**
   * Check if the folder exist on the sharepoint
   *
   * @param name
   */
  private async checkFolderExists(folderPath: string, name: string) {
    this.graphService.getFolderDetails(folderPath).subscribe((res: any) => {
      if (res) {
        if (res.value.filter(function(e) { return e.name === name; }).length == 0) {
          const Mac_name = this.selectedMachineName.replace(/ /g, '');
          const createFolderPath = 'General/Machines' + '/' + Mac_name + '/' + name;
          const folderCreatePayload = {
            name,
            folder: {},
            '@microsoft.graph.conflictBehavior': 'rename'
          };
          this.graphService.createFolder(folderCreatePayload, createFolderPath).subscribe((res) => {
          }, err => {
            console.log(err);
          });
        }
      }
    }, err => {
      console.log(err);
    });
  }

  

}
