<ion-content>
  <div class="flex-container">
    <div class="left-column header-row">
      <div class="ion-text-start header-title">
        {{ 'Select Roadmaps' | translate}}
      </div>
    </div>  
    <div class="right-column">      
      <img class="close-icon" alt="" tooltip="{{'Close'  | translate }}" placement="bottom" (click)="closeModal()" src="assets/icon/cancel.svg">
    </div>  
  </div>

  <div class="main-content">
    <div class="flex-container dct-extra-pad">
    <div class="left-column dash-cont-title">      
      <ion-radio-group name="compliance">
        <div class="radio-item">
          <ion-radio slot="start" value="template" checked class="radio-checked"></ion-radio>
          <ion-label>{{ 'From Templates' | translate }}</ion-label>
        </div>
        <div class="radio-item" disabled>
          <ion-radio slot="start" value="similar" [disabled]="true"></ion-radio>
          <ion-label>{{ 'From Similar Machines' | translate }}</ion-label>
        </div>
      </ion-radio-group>        
    </div>
    <div class="right-column" >
      <app-searchbar class="custom-searchbar" [(ngModel)]="searchText" [showSearchBar]=false placeholder="{{ gridConfigItems.placeholder }}"
           ngDefaultControl (ionChange)="onGridSearchTextChange($event)">
      </app-searchbar>
      <ion-button class="app-large-btn" shape="round" fill="outline" color="primary" (click)="newRoadmap()">
        {{'New Roadmap'|translate}}
        <ion-icon slot=start color="primary" name='add-circle-outline'>
        </ion-icon>
      </ion-button>
      <img tooltip="{{'More actions' | translate }}" alt="" [hidden]="!showKebabIcon" placement="top" class="ellipsis-icon ellipsis-right-margin" 
          src="../../../assets/icon/more.svg" (click)="openStatusPopover($event)">
    </div>
  </div>
    <app-grid *ngIf="roadmaps.length" class="margin-left-112" [ngClass]="isGridDisplayed ? 'show' : 'hide' " [columns]="gridColumnDefs" [data]="roadmaps" [gridConfigItems]="gridConfigItems" (onBtnClick)="newRoadmap()"
      (onCellClick)="fnOnGridCellClick($event)" [showHeader]="false" [showKebabIcon]="false">
    </app-grid>
    <div class="save-button">
      <ion-button class="app-btn" shape="round" [disabled]="this.checkedRoadMapList.length ==0" fill="outline" color="primary" (click)="configure()">
        {{'Configure'|translate}}
      </ion-button>
      <ion-button class="app-btn margin-right-16" shape="round" fill="outline" color="primary" (click)="closeModal()">
        {{'Cancel'|translate}}
      </ion-button>
    </div>
  </div>
</ion-content>